import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import API from 'Lib/axios';
import yup from 'Lib/yup';

import Block from 'Components/@Shared/Block';
import ButtonWrapper from 'Components/@Shared/ButtonWrapper';
import InputWrapper from 'Components/@Shared/InputWrapper';
import PageContainer from 'Components/@Shared/PageContainer';
import PageContent from 'Components/@Shared/PageContent';
import PageHeader from 'Components/@Shared/PageHeader';

import { createToastr, isNotEmptyObject, readFileAsync } from 'Utils/common';
import { GROUPS_ENDPOINTS } from 'Constants/endpoints';
import { GROUPS } from 'Constants/routes';
import { MAX_UPLOAD_SIZE_BYTES, MAX_UPLOAD_SIZE_MEGABYTES } from 'Constants';

const schema = yup.object().shape({
  name: yup.string().notRequired(),
  provider: yup.string().notRequired(),
  description: yup.string().notRequired(),
  database: yup.string().notRequired(),
});

const AddGroup = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef(null);

  const { register, errors, handleSubmit, setError, watch, clearErrors } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const watchAttachment = watch('database');

  const onSubmit = async (data) => {
    if (watchAttachment?.length) {
      const [item] = watchAttachment;
      const file: any = await readFileAsync(item);

      data.database = file.src;
    } else {
      delete data.database;
    }

    setIsSubmitting(true);

    API.post(GROUPS_ENDPOINTS.GROUPS_ADD, { ...data })
      .then((res) => {
        const { status } = res.data;
        toastr.success('sukces', '');
        history.replace(GROUPS);
      })
      .catch((err) => {
        const { data: errorData, status } = err.response;
        createToastr(errorData, status, setError);

        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (watchAttachment) {
      const [item] = watchAttachment;

      if (item?.size > MAX_UPLOAD_SIZE_BYTES) {
        toastr.error(
          t('Coś poszło nie tak'),
          t('Przesłany plik przekracza maksymalny dozwolony rozmiar {{maxFileSize}} MB.', {
            maxFileSize: MAX_UPLOAD_SIZE_MEGABYTES,
          }),
        );
      }
    }
  }, [watchAttachment, t]);

  return (
    <PageContainer>
      <>
        <PageHeader>{t('Dodaj grupę')}</PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <form ref={formRef} onFocus={() => clearErrors()} onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputWrapper
                className="mb-3"
                componentType="input"
                labelProps={{ label: t('Nazwa') }}
                inputProps={{
                  innerRef: register,
                  name: 'name',
                  type: 'text',
                  placeholder: t('Nazwa'),
                }}
                feedback={{
                  message: t(errors.name?.message, { field: t('Nazwa') }),
                  isError: !!errors.name,
                }}
              />

              <ButtonWrapper
                className="mt-5"
                alignment="center"
                type="submit"
                label={t('Zapisz')}
                isDisabled={isSubmitting || isNotEmptyObject(errors)}
                isSubmitting={isSubmitting}
              />
            </form>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default AddGroup;
