import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import * as actionTypes from 'Store/translations/actionTypes';
import { updateFilters } from 'Utils/common';

type initialFiltersType = {
  category: {
    label: string;
    value: string;
  };
  source: {
    label: string;
    value: string;
  };
  translation: {
    label: string;
    value: string;
  };
  is_untranslated: {
    label: string;
    value: string;
  };
};

type TranslationsState = {
  currentTranslation: {
    translation: Array<{
      id: number;
      category: string;
      source: string;
      language_translation: {
        id: number;
        language_source_id: number;
        translation: string;
      };
    }>;
    total: number;
    currentPage: number;
    filters: initialFiltersType;
    filtersParams: string;
    filtersActive: boolean;
    isReady: boolean;
    filtersOpen: boolean;
    locale: string;
  };
  translations: {
    languages: Array<{ id: number; locale: string; name: string; language_translations_count: number }>;
    totalSources: number;
    total: number;
    isReady: boolean;
  };
};

const initialFilters: initialFiltersType = {
  category: {
    label: 'Category',
    value: '',
  },
  source: {
    label: 'Source',
    value: '',
  },
  translation: {
    label: 'Translation',
    value: '',
  },
  is_untranslated: {
    label: 'Status',
    value: '',
  },
};

const initalState: TranslationsState = {
  currentTranslation: {
    translation: [],
    total: 0,
    currentPage: 1,
    filters: { ...initialFilters },
    filtersParams: '',
    filtersActive: false,
    isReady: false,
    filtersOpen: false,
    locale: '',
  },
  translations: {
    languages: [],
    totalSources: 0,
    total: 0,
    isReady: false,
  },
};

export const reducer = (state = initalState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.TOGGLE_TRANSLATION_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        currentTranslation: {
          filtersOpen: {
            $set: filtersOpen,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATIONS: {
      const { languages, totalSources, total } = action.payload;

      return update(state, {
        translations: {
          languages: {
            $set: languages,
          },
          totalSources: {
            $set: totalSources,
          },
          total: {
            $set: total,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATIONS_READY: {
      return update(state, {
        translations: {
          isReady: {
            $set: true,
          },
        },
      });
    }
    case actionTypes.CLEAR_TRANSLATIONS: {
      return update(state, {
        translations: {
          $set: initalState.translations,
        },
      });
    }
    case actionTypes.SET_TRANSLATION_LOCALE: {
      const { locale } = action.payload;

      return update(state, {
        currentTranslation: {
          locale: {
            $set: locale,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATION: {
      const { translation, total } = action.payload;

      return update(state, {
        currentTranslation: {
          translation: {
            $set: translation,
          },
          total: {
            $set: total,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATION_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        currentTranslation: {
          currentPage: {
            $set: currentPage,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATION_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.currentTranslation.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some((key) => updatedFilters[key].value);

      return update(state, {
        currentTranslation: {
          filters: {
            $set: updatedFilters,
          },
          filtersActive: {
            $set: filtersActive,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATION_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        currentTranslation: {
          filtersParams: {
            $set: filtersParams,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATION_FILTERS_READY: {
      return update(state, {
        currentTranslation: {
          isReady: {
            $set: true,
          },
        },
      });
    }
    case actionTypes.RESET_TRANSLATION_FILTERS: {
      return update(state, {
        currentTranslation: {
          filters: {
            $set: initialFilters,
          },
          filtersActive: {
            $set: false,
          },
        },
      });
    }
    case actionTypes.SET_TRANSLATION_READY: {
      return update(state, {
        currentTranslation: {
          isReady: {
            $set: true,
          },
        },
      });
    }
    case actionTypes.CLEAR_TRANSLATION: {
      return update(state, {
        currentTranslation: {
          $set: initalState.currentTranslation,
        },
      });
    }
    default:
      return state;
  }
};
