import { cnb } from 'cnbuilder';
import styles from './PageContent.module.scss';

type PageContentProps = {
  children?: JSX.Element;
};

const PageContent = ({ children, ...rest }: PageContentProps) => (
  <div className="row">
    <div className={cnb(styles.PageContent, 'w-100')} {...rest}>
      {children}
    </div>
  </div>
);

PageContent.defaultProps = {
  children: null,
};

export default PageContent;
