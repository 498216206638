import { PayloadAction } from '@reduxjs/toolkit';

import * as actionTypes from 'Store/modal/actionTypes';

interface IObjectKeys {
  [key: string]: any;
}

interface ModalState extends IObjectKeys {
  modal?: {
    isOpen: boolean;
  };
}

const initialState: ModalState = {};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SHOW: {
      const { modal, props } = action.payload;

      return {
        ...state,
        [modal]: {
          isOpen: true,
          props,
        },
      };
    }
    case actionTypes.HIDE: {
      const { modal } = action.payload;

      return {
        ...state,
        [modal]: {
          ...state[modal],
          isOpen: false,
        },
      };
    }
    case actionTypes.DESTROY: {
      const { modal } = action.payload;
      const nextState = { ...state };

      delete nextState[modal];

      return nextState;
    }
    default:
      return state;
  }
};
