import update from 'immutability-helper';
import * as actionTypes from 'Store/coordinatorSchemas/actionTypes';

type SchemasState = {
  schemas: Array<{
    id: number;
    email: string;
    role: string;
    owned_groups?: any;
    groups?: any;
  }>;
  schema: Array<{
    id: number;
    name: string;
  }>;
  isReady: boolean;
  isReadySchema: boolean;
  // total: number;
};

const initialState: SchemasState = {
  schemas: [],
  schema: [],
  isReady: false,
  isReadySchema: false,
  // total: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCHEMAS: {
      const { schemas } = action.payload;

      return update(state, {
        schemas: {
          $set: schemas,
        },
        // total: {
        //   $set: total,
        // },
      });
    }
    case actionTypes.SET_SCHEMAS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_SCHEMAS: {
      return update(state, {
        schemas: {
          $set: [],
        },
        isReady: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_SCHEMA: {
      const { schemas } = action.payload;

      return update(state, {
        schema: {
          $set: schemas,
        },
        // total: {
        //   $set: total,
        // },
      });
    }
    case actionTypes.SET_SCHEMA_READY: {
      return update(state, {
        isReadySchema: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_SCHEMA: {
      return update(state, {
        schema: {
          $set: [],
        },
        isReadySchema: {
          $set: false,
        },
      });
    }
    default:
      return state;
  }
};
