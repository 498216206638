import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateFilters } from 'Utils/common';

import * as actionTypes from 'Store/users/actionTypes';

type TicketsState = {
  users: any;
  total: number;
  currentPage: number;
  filters: {};
  filtersParams: string;
  filtersActive: boolean;
  isReady: boolean;
  filtersOpen: boolean;
};

type initialFiltersType = {
  is_blocked: {
    label: string;
    value: string;
  };
  term_from: {
    label: string;
    value: string;
  };
  term_to: {
    label: string;
    value: string;
  };
  date_sort: {
    label: string;
    value: string;
    skipActive: boolean;
  };
};

const initialFilters: initialFiltersType = {
  is_blocked: {
    label: 'Is blocked',
    value: '',
  },
  term_from: {
    label: 'Data od',
    value: '',
  },
  term_to: {
    label: 'Data do',
    value: '',
  },
  date_sort: {
    label: '[DateSort]',
    value: 'desc',
    skipActive: true,
  },
};

const initialState: TicketsState = {
  users: [],
  total: 0,
  currentPage: 1,
  filters: { ...initialFilters },
  filtersParams: '',
  filtersActive: false,
  isReady: false,
  filtersOpen: false,
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.TOGGLE_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        filtersOpen: {
          $set: filtersOpen,
        },
      });
    }
    case actionTypes.SET_USERS: {
      const { users } = action.payload;

      return update(state, {
        users: {
          $set: users,
        },
      });
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        currentPage: {
          $set: currentPage,
        },
      });
    }
    case actionTypes.SET_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some(
        (key) => updatedFilters[key].value && !updatedFilters[key].skipActive,
      );

      return update(state, {
        filters: {
          $set: updatedFilters,
        },
        filtersActive: {
          $set: filtersActive,
        },
      });
    }
    case actionTypes.SET_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        filtersParams: {
          $set: filtersParams,
        },
      });
    }
    case actionTypes.RESET_FILTERS: {
      return update(state, {
        filters: {
          $set: initialFilters,
        },
        filtersActive: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_USERS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_USERS: {
      return update(state, {
        $set: initialState,
      });
    }
    default:
      return state;
  }
};
