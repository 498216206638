import { Switch, Route as RRDRoute } from 'react-router-dom';

import { useAppSelector } from 'Hooks/redux-hooks';

import { ROLE_TYPE_COORDINATOR } from 'Constants';

import Route from 'Routes/Route';

import { ROUTE_CONFIG } from 'Routes/config';

import NotFound from 'Containers/NotFound';

const Routes = () => {
  const { role } = useAppSelector((state) => state.user);
  const isCoordinator = role === ROLE_TYPE_COORDINATOR;

  return (
    <Switch>
      {ROUTE_CONFIG.map(({ id, coordinator, component, ...rest }) => {
        if (coordinator && !isCoordinator) {
          return null;
        }

        return <Route key={id} component={component} {...rest} />;
      })}

      <RRDRoute key="404" component={NotFound} />
    </Switch>
  );
};

export default Routes;
