import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import * as actionTypes from 'Store/menuNotifications/actionTypes';

const initialState = {
  notifications: {},
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SET_MENU_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return update(state, {
        notifications: {
          $set: notifications,
        },
      });
    }
    default:
      return state;
  }
};
