import { useTranslation } from 'react-i18next';

import { DesktopDateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'moment/locale/pl';

import styles from './DateTimePicker.module.scss';

const DateTimePicker = (props: any) => {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
      <MUIDateTimePicker
        className="w-100"
        ampm={false}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              sx={{
                '.MuiInputBase-root': {
                  width: '100%',
                },
              }}
              className={styles.DateTimePicker}
            />
          );
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
