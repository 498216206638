import { cnb } from 'cnbuilder';

import styles from './DotLoader.module.scss';

type DotLoaderProps = {
  className?: string;
};

const DotLoader = ({ className, ...rest }: DotLoaderProps) => (
  <div className={cnb(styles.DotLoader, className)} {...rest}>
    <div className={cnb(styles.DotLoader__dot, styles.DotLoader__dot_1)} />
    <div className={cnb(styles.DotLoader__dot, styles.DotLoader__dot_2)} />
    <div className={styles.DotLoader__dot} />
  </div>
);

DotLoader.defaultProps = {
  className: '',
};

export default DotLoader;
