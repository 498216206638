import { useEffect } from 'react';
import { cnb } from 'cnbuilder';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';

import { toggleSidebarAction } from 'Store/app/actions';

import styles from './Hamburger.module.scss';

type HamburgerProps = {
  isDesktop: boolean;
};

const Hamburger = ({ isDesktop }: HamburgerProps) => {
  const dispatch = useAppDispatch();
  const { sidebarOpen } = useAppSelector((state) => state.app);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebarAction(!sidebarOpen));
  };

  useEffect(() => {
    if (sidebarOpen) {
      document.body.classList.add('sidebar--is-open');
    } else {
      document.body.classList.remove('sidebar--is-open');
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (sidebarOpen && isDesktop) {
      dispatch(toggleSidebarAction(false));
    }
  }, [dispatch, sidebarOpen, isDesktop]);

  return (
    <button
      type="button"
      className={cnb(styles.Hamburger, sidebarOpen && styles.Hamburger_isOpen)}
      onClick={handleToggleSidebar}
    >
      <span className={styles.Hamburger__inner} />
    </button>
  );
};

export default Hamburger;
