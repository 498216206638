import { cnb } from 'cnbuilder';

import Icon from 'Components/@Base/Icon';

import styles from './TableCell.module.scss';

type TableCellProps = {
  className: string;
  cellHeader?: string | JSX.Element;
  useEllipsis?: boolean;
  children?: any;
  useIcon?: any;
  icon?: any;
  onClick?: any;
};

const TableCell = ({
  className,
  cellHeader,
  children,
  useIcon,
  icon: SortIcon,
  useEllipsis,
  onClick,
}: TableCellProps) => (
  <div className={cnb(styles.UsersTableCell, !cellHeader && styles.UsersTableCell__header, className)}>
    <div className={cnb(styles.UsersTableCel__inner, useEllipsis && styles.UsersTableCel__inner_ellipsis)}>
      <div className={styles.UsersTableCell__cellHeader}>{cellHeader}</div>

      {useIcon ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className={styles.UsersTableCell__content} onClick={onClick}>
          {children}
          <Icon className={styles.UsersTableCel__icon}>
            <SortIcon />
          </Icon>
        </div>
      ) : (
        children
      )}
    </div>
  </div>
);

TableCell.defaultProps = {
  cellHeader: '',
  useEllipsis: false,
  children: null,
  useIcon: undefined,
  icon: undefined,
  onClick: undefined,
};

export default TableCell;
