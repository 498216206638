import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useHistory, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import API from 'Lib/axios';
import yup from 'Lib/yup';

import Block from 'Components/@Shared/Block';
import ButtonWrapper from 'Components/@Shared/ButtonWrapper';
import InputWrapper from 'Components/@Shared/InputWrapper';
import PageContainer from 'Components/@Shared/PageContainer';
import PageContent from 'Components/@Shared/PageContent';
import PageHeader from 'Components/@Shared/PageHeader';

import { GROUPS_ENDPOINTS } from 'Constants/endpoints';
import { GROUPS } from 'Constants/routes';

import { createToastr, isNotEmptyObject } from 'Utils/common';

type RequestTypes = {
  group: {
    id: number;
    hall: number;
    event: number;
    estimated_price: number;
    from: string;
    to: string;
    name: string;
    people: number;
    additional_cost: number;
    custom_reply: boolean;
    reply: string;
  };
};

const schema = yup.object().shape({
  event: yup.string().nullable().notRequired(),
  hall: yup.string().nullable().notRequired(),
  people: yup.string().notRequired(),
  from: yup.string().nullable().notRequired(),
  to: yup.string().nullable().notRequired(),
  estimated_price: yup.string().notRequired(),
  additional_cost: yup.string().notRequired(),
  name: yup.string().notRequired(),
  custom_reply: yup.bool().notRequired(),
  reply: yup.string().notRequired().when('custom_reply', {
    is: true,
    then: yup.string().notRequired(),
  }),
  offer: yup.string().notRequired(),
});

const EditGroup = () => {
  const location = useLocation<RequestTypes>();
  const { t } = useTranslation();
  const history = useHistory();

  const { id, name } = location.state.group;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, errors, handleSubmit, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
    },
  });
  const formRef = useRef(null);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    API.put(`${GROUPS_ENDPOINTS.GROUPS_EDIT}/${id}`, { ...data })
      .then((res) => {
        const { title, message } = res.data;
        toastr.success(title, message);

        history.replace(GROUPS);
      })
      .catch((err) => {
        const { data: errorData, status } = err.response;
        createToastr(errorData, status, setError);
        setIsSubmitting(false);
      });
  };

  return (
    <PageContainer>
      <>
        <PageHeader>{t('Edytuj request')}</PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputWrapper
                className="mb-3"
                componentType="input"
                labelProps={{ label: t('Nazwa') }}
                inputProps={{
                  innerRef: register,
                  name: 'name',
                  type: 'text',
                  placeholder: t('Nazwa'),
                }}
                feedback={{
                  message: t(errors.name?.message, { field: t('Nazwa') }),
                  isError: !!errors.name,
                }}
              />
              <ButtonWrapper
                className="mt-5"
                alignment="center"
                label={t('Zapisz')}
                type="submit"
                isDisabled={isSubmitting || isNotEmptyObject(errors)}
                isSubmitting={isSubmitting}
              />
            </form>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default EditGroup;
