import { Suspense } from 'react';
import { cnb } from 'cnbuilder';
import { useAppSelector } from 'Hooks/redux-hooks';

import LanguageSelector from 'Components/@Shared/LanguageSelector';
import PageLoader from 'Components/@Shared/PageLoader';

import styles from './DefaultLayout.module.scss';

type DefaultLayoutProps = {
  children: JSX.Element;
};

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const { appLoading } = useAppSelector((state) => state.app);

  return (
    <Suspense fallback={<PageLoader />}>
      <div className={cnb(styles.DefaultLayout, 'container-fluid p-3 py-sm-5 px-sm-0')}>
        <div className="d-flex justify-content-end">
          {appLoading && (
            <nav className={styles.DefaultLayout__LangugageSelector}>
              <LanguageSelector />
            </nav>
          )}
        </div>

        <div className={styles.DefaultLayout__app}>{children}</div>
      </div>
    </Suspense>
  );
};

export default DefaultLayout;
