import { cnb } from 'cnbuilder';
import styles from './PageHeader.module.scss';

type PageHeaderProps = {
  className?: string;
  children?: JSX.Element;
};

const PageHeader = ({ className, children, ...props }: PageHeaderProps) => (
  <div className="row">
    <h1 className={cnb(styles.PageHeader, 'mb-0 pb-3 px-3 pb-md-5', className)} {...props}>
      {children}
    </h1>
  </div>
);

PageHeader.defaultProps = {
  className: '',
  children: null,
};

export default PageHeader;
