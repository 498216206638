import { cnb } from 'cnbuilder';

import { ReactComponent as CheckIcon } from 'Assets/icons/check.svg';

import styles from './Checkbox.module.scss';

type CheckboxProps = {
  className?: string;
  innerRef?: any;
  label?: string | JSX.Element;
  name?: string;
  handleClick?: any;
  isChecked?: any;
};

const Checkbox = ({ className, innerRef, label, name, isChecked, handleClick, ...rest }: CheckboxProps) => {
  return (
    <div className="d-flex">
      <label className={cnb(styles.Checkbox, className)} htmlFor={name}>
        <input
          id={name}
          ref={innerRef}
          className={styles.Checkbox__input}
          type="checkbox"
          name={name}
          onChange={handleClick}
          checked={isChecked}
          {...rest}
        />
        <span className={styles.Checkbox__checkbox}>
          <figure
            className={cnb(styles.Checkbox__check, 'd-inline-flex align-items-center justify-content-center mb-0')}
          >
            <CheckIcon />
          </figure>
        </span>
        {label}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  innerRef: undefined,
  className: '',
  handleClick: undefined,
  isChecked: undefined,
  label: '',
  name: '',
};

export default Checkbox;
