import React from 'react';
import { MdKeyboardArrowDown, GiHamburgerMenu } from 'react-icons/all';

import Icon from 'Components/@Base/Icon';
import Dropdown from 'Components/@Base/Dropdown';
import { cnb } from 'cnbuilder';

import styles from 'Components/@Base/Dropdown/Dropdown.module.scss';

const DropdownWithIcon =
  (WrappedComponent: React.ElementType) =>
  ({
    header,
    children,
    className,
    isAction = false,
    ...rest
  }: {
    header: string;
    className: string;
    children: JSX.Element;
    // eslint-disable-next-line react/require-default-props
    isAction?: boolean;
  }) => {
    const headerWithIcon = (
      <>
        {header !== 'Actions' && header !== 'Akcje' ? (
          <span>{header}</span>
        ) : (
          <Icon>
            <GiHamburgerMenu style={{ height: '20px', width: '20px' }} />
          </Icon>
        )}

        <Icon className={cnb(styles.arrow, 'ml-1')}>
          <MdKeyboardArrowDown style={{ height: '20px', width: '20px' }} />
        </Icon>
      </>
    );

    return (
      <WrappedComponent isAction={isAction} header={headerWithIcon} className={className} {...rest}>
        {children}
      </WrappedComponent>
    );
  };

export default DropdownWithIcon(Dropdown);
