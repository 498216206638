import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { MdKeyboardArrowDown } from 'react-icons/all';
import { cnb } from 'cnbuilder';
import Collapse from '@kunukn/react-collapse';

import { useAppSelector } from 'Hooks/redux-hooks';

import { getCountryIso } from 'Utils/common';

import useEventListener from 'Hooks/use-event-listener';

import Icon from 'Components/@Base/Icon';

import styles from './MobileLanguageSelector.module.scss';

const MobileLanguageSelector = () => {
  const { i18n } = useTranslation();
  const { languages } = useAppSelector((state) => state.app);
  const [isOpen, setIsOpen] = useState(false);
  const [collapseState, setCollapseState] = useState('');
  const dropdownRef = useRef(null);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  const handleClickOutside = useCallback(
    (event) => {
      const { target } = event;
      const { current: dropdown } = dropdownRef;

      if (dropdown && isOpen && !dropdown.contains(target)) {
        handleToggle();
      }
    },
    [dropdownRef, isOpen, handleToggle],
  );

  const handleOnChange = ({ state }) => {
    setCollapseState(state);
  };

  useEventListener('click', handleClickOutside);

  return (
    <div
      ref={dropdownRef}
      className={cnb(styles.Dropdown, isOpen && styles.Dropdown_isExpanded, `${collapseState}Drop`)}
    >
      <button className={styles.Dropdown__header} type="button" onClick={handleToggle}>
        <div className={styles.LanguageSelector__icon}>
          <ReactSVG src={`/flags/${getCountryIso(i18n.language)}.svg`} />
        </div>
        <Icon className="ml-lg-3">
          <MdKeyboardArrowDown style={{ height: '20px', width: '20px' }} />
        </Icon>
      </button>

      <Collapse className={styles.Dropdown__collapse} isOpen={isOpen} onChange={handleOnChange}>
        <div className={styles.Dropdown__content}>
          {languages.map(({ id, locale }) => {
            if (locale === i18n.language) {
              return null;
            }

            return (
              <button key={id} type="button" onClick={() => i18n.changeLanguage(locale)}>
                <div className={styles.LanguageSelector__icon}>
                  <ReactSVG src={`/flags/${getCountryIso(locale)}.svg`} />
                </div>
              </button>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default MobileLanguageSelector;
