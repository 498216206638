export const SET_LEADS = '@@leads/SET_LEADS';
export const SET_LEADS_READY = '@@leads/SET_LEADS_READY';
export const SET_CURRENT_PAGE = '@@leads/SET_CURRENT_PAGE';
export const SET_FILTERS = '@@leads/SET_FILTERS';
export const SET_FILTERS_PARAMS = '@@leads/SET_FILTERS_PARAMS';
export const RESET_FILTERS = '@@leads/RESET_FILTERS';
export const TOGGLE_FILTERS_SIDEBAR = '@@leads/TOGGLE_FILTERS_SIDEBAR';
export const CLEAR_LEADS = '@@leads/CLEAR_LEADS';
export const SET_LEAD_DETAILS = '@@leads/SET_LEAD_DETAILS';
export const SET_LEAD_DETAILS_READY = '@@leads/SET_LEAD_DETAILS_READY';
export const CLEAR_LEAD_DETAILS = '@@leads/CLEAR_LEAD_DETAILS';
