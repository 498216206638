export const VERIFICATION_STATUS_ADDED = 1;
export const VERIFICATION_STATUS_EMAIL_PENDING = 2;
export const VERIFICATION_STATUS_EMAIL = 3;

export const VERIFICATION_STATUS = {
  [VERIFICATION_STATUS_ADDED]: 'Not verified',
  [VERIFICATION_STATUS_EMAIL_PENDING]: 'Waiting for email verification',
  [VERIFICATION_STATUS_EMAIL]: 'Verified',
};

export const ETAP_WSTĘP = 'introduction';
export const ETAP_RELACJI = 'build_relationship';
export const ETAP_SPRZEDAŻY = 'sale';
export const ETAP_ZOBOWIĄZANIE = 'obligation';

export const ETAP_STATUS = {
  [ETAP_WSTĘP]: 'Wstęp',
  [ETAP_RELACJI]: 'Budowanie relacji',
  [ETAP_SPRZEDAŻY]: 'Dopięcie sprzedaży',
  [ETAP_ZOBOWIĄZANIE]: 'Zobowiązanie',
};

export const VERIFICATION_STATUS_SELECT = [
  {
    value: VERIFICATION_STATUS_ADDED,
    label: 'Not verified',
  },
  {
    value: VERIFICATION_STATUS_EMAIL_PENDING,
    label: 'Waiting for email verification',
  },
  {
    value: VERIFICATION_STATUS_EMAIL,
    label: 'Verified',
  },
];

export const TYPE_COMPANY = 1;
export const TYPE_PERSONAL = 2;

export const BLOCKED_TYPES = {
  0: 'No',
  1: 'Yes',
};

export const BLOCKED_TYPES_SELECT = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Yes',
  },
];

export const AVAILABLE_STATUS = 1;
export const OFFER_PRESENTED_STATUS = 2;
export const RESERVATION_STATUS = 3;
export const CLOSED_STATUS = 4;

export const CALENDAR_STATUSES = {
  [AVAILABLE_STATUS]: 'Available',
  [OFFER_PRESENTED_STATUS]: 'Offer presented',
  [RESERVATION_STATUS]: 'Reservation',
  [CLOSED_STATUS]: 'Closed',
};

export const CALENDAR_STATUS_COLORS = {
  [AVAILABLE_STATUS]: '#8ac6755c',
  [OFFER_PRESENTED_STATUS]: '#ffd42c61',
  [RESERVATION_STATUS]: '#ff000038',
  [CLOSED_STATUS]: '#8a8a8a85',
};
