import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import * as actionTypes from 'Store/coordinatorAnalytics/actionTypes';

type AnalyticsState = {
  reports_by_users: any;
  reports_by_perc: any;
  reports_by_stage: any;
  reports_by_time: any;
  reports_by_schema: any;
  reports_by_failure: any;
  reports_by_success: any;
};

const initialState: AnalyticsState = {
  reports_by_users: [],
  reports_by_perc: [],
  reports_by_stage: [],
  reports_by_time: [],
  reports_by_schema: [],
  reports_by_failure: [],
  reports_by_success: [],
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SET_REPORTS_BY_USERS: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_users: {
          $set: reports,
        },
      });
    }
    case actionTypes.SET_REPORTS_BY_COMPLETION_PERC: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_perc: {
          $set: reports,
        },
      });
    }
    case actionTypes.SET_REPORTS_BY_STAGE: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_stage: {
          $set: reports,
        },
      });
    }
    case actionTypes.SET_REPORTS_BY_TIME: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_time: {
          $set: reports,
        },
      });
    }
    case actionTypes.SET_REPORTS_BY_SCHEMA: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_schema: {
          $set: reports,
        },
      });
    }
    case actionTypes.SET_REPORTS_FAILURE: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_failure: {
          $set: reports,
        },
      });
    }
    case actionTypes.SET_REPORTS_SUCCESS: {
      const { reports } = action.payload;

      return update(state, {
        reports_by_success: {
          $set: reports,
        },
      });
    }
    default:
      return state;
  }
};
