import { cnb } from 'cnbuilder';

import { useAppSelector } from 'Hooks/redux-hooks';

import Loader from 'Components/@Shared/Loader';

import Header from 'Layouts/WithSidebarLayout/components/Header';
import Sidebar from 'Layouts/WithSidebarLayout/components/Sidebar';
import Breadcrumbs from 'Layouts/WithSidebarLayout/components/Breadcrumbs';

import styles from './WithSidebarLayout.module.scss';

type ChildrenProps = {
  children: JSX.Element;
};

const WithSidebarLayout = ({ children }: ChildrenProps) => {
  const { isReady } = useAppSelector((state) => state.user);
  return (
    <div className={cnb(styles.WithSidebarLayout)}>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className={styles.WithSidebarLayout__contentWrapper}>
            <Sidebar />
            <div className={cnb(styles.WithSidebarLayout__content, 'px-lg-3')}>
              <>
                <Breadcrumbs />

                {!isReady ? <>{children}</> : <Loader minHeight="300px" />}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithSidebarLayout;
