export const TICKET_STATUS_SUPPORT_WAITING = 1;
export const TICKET_STATUS_SUPPORT_REPLIED = 2;
export const TICKET_STATUS_CUSTOMER_WAITING = 3;
export const TICKET_STATUS_CUSTOMER_REPLIED = 4;
export const TICKET_STATUS_CLOSED = 5;

export const TICKET_STATUSES = {
  [TICKET_STATUS_SUPPORT_WAITING]: 'Waiting for support',
  [TICKET_STATUS_SUPPORT_REPLIED]: 'Wsparcie replied',
  [TICKET_STATUS_CUSTOMER_WAITING]: 'Waiting for customer',
  [TICKET_STATUS_CUSTOMER_REPLIED]: 'Customer replied',
  [TICKET_STATUS_CLOSED]: 'Closed',
};
