import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import * as actionTypes from 'Store/user/actionTypes';

type UserState = {
  loggedIn: boolean;
  loggedOut: boolean;
  role_type?: number;
  role?: string;
  phone?: number;
  email?: string;
  id?: number;
  verification_status?: number;
  last_login_at?: string;
  last_login_ip?: string;
  created_at?: string;
  user_details?: {
    first_name?: string;
    last_name?: string;
    position?: string;
  };
  isReady: boolean;
};

const initialState: UserState = {
  loggedIn: false,
  loggedOut: false,
  isReady: false,
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SET_LOGGED_IN: {
      const { loggedIn } = action.payload;

      return update(state, {
        loggedIn: {
          $set: loggedIn,
        },
        loggedOut: {
          $set: !loggedIn,
        },
      });
    }
    case actionTypes.SET_DETAILS: {
      const { details } = action.payload;

      return update(state, {
        $merge: details,
      });
    }
    case actionTypes.SET_DASHBOARD_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.LOGOUT: {
      const { loggedOut } = action.payload;
      return update(initialState, {
        loggedOut: {
          $set: loggedOut,
        },
        loggedIn: {
          $set: !loggedOut,
        },
      });
    }
    default:
      return state;
  }
};
