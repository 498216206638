import { components } from 'react-select';

import styles from './SelectMultiValueRemove.module.scss';

type SelectMultiValueRemoveProps = {
  innerProps: any;
  clearValue: any;
  cx: any;
  getStyles: any;
  getValue: any;
  hasValue: any;
  isMulti: any;
  isRtl: any;
  options: any;
  selectOption: any;
  selectProps: any;
  setValue: any;
  theme: any;
  children: any;
  data: any;
};

const SelectMultiValueRemove = ({ innerProps, ...rest }: SelectMultiValueRemoveProps) => {
  return <components.MultiValueRemove {...rest} innerProps={{ ...innerProps, className: styles.MultiValueRemove }} />;
};

export default SelectMultiValueRemove;
