import { cnb } from 'cnbuilder';
import { Link } from 'react-router-dom';

import { HOME } from 'Constants/routes';

import logo2 from 'Assets/images/logo_MeMentor1.png';

import styles from './Logo.module.scss';

const Logo = () => {
  return (
    <div className={cnb(styles.Logo, 'px-3')}>
      <Link to={HOME}>
        {' '}
        <img src={logo2} alt="logo" />
      </Link>
    </div>
  );
};

export default Logo;
