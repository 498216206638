import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateFilters } from 'Utils/common';

import * as actionTypes from 'Store/usersAll/actionTypes';

type CustomersState = {
  customers: Array<{
    id: number;
    nip: string;
    type: number;
    fb_lead_id: number;
    first_name: string;
    last_name: string;
    email: string;
    company_name: string;
    phone: number;
    status: number;
    source: number;
    lastOrder: string;
    ordersCount: number;
    ghosted: number;
    ghosted_by: string;
    updated_at: string;
    updated_by: {
      first_name: string;
      id: number;
      last_name: string;
      user_id: number;
    };
    created_at: string;
    created_by: {
      first_name: string;
      id: number;
      last_name: string;
      user_id: number;
    };
    area_code: string;
    leads: {
      id: number;
      nip: string;
      history: Array<{
        id: number;
        status: number;
        note: string;
        created_at: string;
        created_by: number;
      }>;
    };
  }>;
  total: number;
  currentPage: number;
  filters: {};
  filtersParams: string;
  filtersActive: boolean;
  isReady: boolean;
  filtersOpen: boolean;
};

type initialFiltersType = {
  last_name: {
    label: string;
    value: string;
  };
  email: {
    label: string;
    value: string;
  };
  phone: {
    label: string;
    value: string;
  };
  status: {
    label: string;
    value: string;
  };
  created_at_from: {
    label: string;
    value: string;
  };
  created_at_to: {
    label: string;
    value: string;
  };
  created_by: {
    label: string;
    value: string;
  };
};

const initialFilters: initialFiltersType = {
  last_name: {
    label: 'Last name',
    value: '',
  },
  email: {
    label: 'Email',
    value: '',
  },
  phone: {
    label: 'Phone',
    value: '',
  },
  status: {
    label: 'Status',
    value: '',
  },
  created_at_from: {
    label: 'Created at from',
    value: '',
  },
  created_at_to: {
    label: 'Created at to',
    value: '',
  },
  created_by: {
    label: 'Created by',
    value: '',
  },
};

const initialState: CustomersState = {
  customers: [],
  total: 0,
  currentPage: 1,
  filters: { ...initialFilters },
  filtersParams: '',
  filtersActive: false,
  isReady: false,
  filtersOpen: false,
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.TOGGLE_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        filtersOpen: {
          $set: filtersOpen,
        },
      });
    }
    case actionTypes.SET_CUSTOMERS: {
      const { customers, total } = action.payload;

      return update(state, {
        customers: {
          $set: customers,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        currentPage: {
          $set: currentPage,
        },
      });
    }
    case actionTypes.SET_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some(
        (key) => updatedFilters[key].value && !updatedFilters[key].skipActive,
      );

      return update(state, {
        filters: {
          $set: updatedFilters,
        },
        filtersActive: {
          $set: filtersActive,
        },
      });
    }
    case actionTypes.SET_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        filtersParams: {
          $set: filtersParams,
        },
      });
    }
    case actionTypes.RESET_FILTERS: {
      return update(state, {
        filters: {
          $set: initialFilters,
        },
        filtersActive: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_CUSTOMERS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_CUSTOMERS: {
      return update(state, {
        $set: initialState,
      });
    }
    default:
      return state;
  }
};
