import update from 'immutability-helper';
import * as actionTypes from 'Store/calendar/actionTypes';

type CalendarState = {
  calendar: Array<{
    date: string;
    halls: Array<{
      hall_id: number;
      status: number;
      requests: number;
      orders: number;
    }>;
  }>;
  isReady: boolean;
  total: number;
};

const initialState: CalendarState = {
  calendar: [],
  isReady: false,
  total: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CALENDAR: {
      const { calendar, total } = action.payload;

      return update(state, {
        calendar: {
          $set: calendar,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_CALENDAR_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.SET_CALENDAR_NOT_READY: {
      return update(state, {
        isReady: {
          $set: false,
        },
      });
    }
    default:
      return state;
  }
};
