import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';

import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'Hooks/redux-hooks';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import AnalyticsTable from 'Containers/Analytics/components/AnalyticsTable/AnalyticsTable';
import { styled } from '@mui/material/styles';
import {
  getReportsByCompletionPerc,
  getReportsBySchema,
  getReportsByStage,
  getReportsByTime,
  getReportsByUsers,
  getReportsFailure,
  getReportsSuccess,
} from 'Store/coordinatorAnalytics/actions';
import { clearUsersAction, getUsers } from 'Store/users/actions';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(14),
  fontFamily: ['Nunito'],
  padding: '5px 10px',
  color: 'gray',
  '&.Mui-selected': {
    color: 'blue',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Analytics = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { users } = useAppSelector((state) => state.users);
  const {
    reports_by_users,
    reports_by_time,
    reports_by_stage,
    reports_by_perc,
    reports_by_failure,
    reports_by_schema,
    reports_by_success,
  } = useAppSelector((state) => state.coordinatorAnalytics);

  useEffect(() => {
    dispatch(getUsers());
    return () => {
      dispatch(clearUsersAction());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReportsByUsers());
    dispatch(getReportsByCompletionPerc());
    dispatch(getReportsByStage());
    dispatch(getReportsByTime());
    dispatch(getReportsBySchema());
    dispatch(getReportsFailure());
    dispatch(getReportsSuccess());
  }, [dispatch]);

  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>{t('Analityka')}</div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <StyledTab label="Raporty użytkowników" {...a11yProps(0)} />
                    <StyledTab label="Raporty według ukończonego procentu" {...a11yProps(1)} />
                    <StyledTab label="Raporty według etapów" {...a11yProps(2)} />
                    <StyledTab label="Raport według czasu" {...a11yProps(3)} />
                    <StyledTab label="Raporty według schematu" {...a11yProps(4)} />
                    <StyledTab label="Wszystkie raporty sukcesu" {...a11yProps(5)} />
                    <StyledTab label="Wszystkie nieudany zgłoszenia" {...a11yProps(6)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_users} type="user" />
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_perc} type="perc" usersData={users} />
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_stage} type="stage" usersData={users} />
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={3}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_time} type="time" usersData={users} />
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={4}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_schema} type="schema" usersData={users} />
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={5}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_success} type="success" />
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={6}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <AnalyticsTable data={reports_by_failure} type="failure" />
                  </div>
                </CustomTabPanel>
              </Box>
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default Analytics;
