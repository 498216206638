import ReactSelect from 'react-select/async';
import { cnb } from 'cnbuilder';

import DropdownIndicator from 'Components/@Base/SelectDropdownIndicator';
import MultiValueContainer from 'Components/@Base/SelectMultiValueContainer';
import MultiValueLabel from 'Components/@Base/SelectMultiValueLabel';
import MultiValueRemove from 'Components/@Base/SelectMultiValueRemove';

import styles from 'Components/@Base/Select/Select.module.scss';

type AsyncSelectProps = {
  className?: string;
  onChange: any;
};

const AsyncSelect = ({ className, ...rest }: AsyncSelectProps) => {
  return (
    <ReactSelect
      components={{ DropdownIndicator, MultiValueContainer, MultiValueLabel, MultiValueRemove }}
      className={cnb(styles.Select, className)}
      classNamePrefix="Select"
      getOptionValue={(option) => (typeof option.value !== 'undefined' ? option.value : option.id)}
      {...rest}
    />
  );
};

AsyncSelect.defaultProps = {
  className: '',
};

export default AsyncSelect;
