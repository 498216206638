import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAppSelector } from 'Hooks/redux-hooks';

import * as ROUTE_TYPES from 'Constants/routeTypes';

import { LOGIN, HOME } from 'Constants/routes';

import DefaultLayout from 'Layouts/DefaultLayout';
import WithSidebarLayout from 'Layouts/WithSidebarLayout';

type RouteWrapperProps = {
  component: React.ElementType;
  path: string;
  type: number;
  admin?: boolean;
};

const RouteWrapper = ({ component: Component, path, type, ...rest }: RouteWrapperProps) => {
  const { loggedIn } = useAppSelector((state) => state.user);

  if (type === ROUTE_TYPES.TYPE_PUBLIC && loggedIn) {
    return <Redirect to={HOME} />;
  }

  if (type === ROUTE_TYPES.TYPE_PRIVATE && !loggedIn) {
    return <Redirect to={{ pathname: LOGIN, state: { from: path } }} />;
  }

  let Layout = loggedIn ? WithSidebarLayout : DefaultLayout;

  if (!path) {
    Layout = DefaultLayout;
  }

  return (
    <Route
      path={path}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
      {...rest}
    />
  );
};

RouteWrapper.defaultProps = {
  admin: false,
};

export default RouteWrapper;
