import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cnb } from 'cnbuilder';

import styles from './NoResults.module.scss';

type NoResultsProps = {
  hasActiveFilters?: boolean;
};

// eslint-disable-next-line no-unused-vars
const NoResults = forwardRef(({ hasActiveFilters, ...rest }: NoResultsProps, _) => {
  const { t } = useTranslation();

  return (
    <p className={cnb(styles.Message, 'my-3')} {...rest}>
      {t(
        hasActiveFilters
          ? 'Nie zwrócono żadnych wyników pasujących do wybranych filtrów.'
          : 'Nie zwrócono żadnych wyników.',
      )}
    </p>
  );
});

NoResults.defaultProps = {
  hasActiveFilters: false,
};

export default NoResults;
