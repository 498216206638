import { useEffect, useState } from 'react';

function Timer({ handleStartTimer }: any) {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    const now = new Date().getTime();
    setStartTime(now);
    setElapsedTime(0);
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      setElapsedTime(elapsedSeconds);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [startTime]);

  handleStartTimer(elapsedTime);

  return <div />;
}

export default Timer;
