import { cnb } from 'cnbuilder';

import styles from './Textarea.module.scss';

type TextareaProps = {
  className?: string;
  innerRef?: any;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  defaultValue?: any;
  onBlur?: any;
};

const Textarea = ({ className, innerRef, defaultValue, name, value, onBlur, disabled, ...rest }: TextareaProps) => {
  return (
    <div className={cnb(styles.TextareaWrapper, className)}>
      <textarea
        ref={innerRef}
        className={styles.Textarea}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  );
};

Textarea.defaultProps = {
  className: '',
  innerRef: undefined,
  name: '',
  value: '',
  disabled: false,
  defaultValue: undefined,
  onBlur: undefined,
};

export default Textarea;
