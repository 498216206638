import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from 'Containers/Bot/components/Cards/Cards.module.scss';
import { BsShieldFillCheck } from 'react-icons/bs';
import { FaPiggyBank } from 'react-icons/fa';

const Cards = ({ item, setIsReadySchema, setSchemaId, setCurrentSchema }: any) => {
  const { t } = useTranslation();
  // console.log('item', item);

  const handleSetSchema = () => {
    setIsReadySchema(true);
    setSchemaId(item.id);
    setCurrentSchema(item);
  };

  return (
    <button type="button" className={styles.button} onClick={() => handleSetSchema()}>
      <Card
        sx={{
          minWidth: 350,
          // maxWidth: 350,
          backgroundColor: 'ButtonFace',
          borderRadius: 5,
          margin: 2,
          transition: 'background-color 0.4s ease',
          '&:hover': { backgroundColor: 'lightgray' },
          boxShadow: '0px 14px 39px -27px rgba(66, 68, 90, 1);',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              mb: 1.5,
            }}
          >
            <Typography sx={{ fontSize: '10', fontWeight: 'bold', textAlign: 'left' }} variant="h5" component="div">
              {item.title}
            </Typography>
            <Box sx={{ minWidth: '30px' }}>
              {item.type === 'finanse' ? (
                <FaPiggyBank fontSize={25} color="#135fc2" />
              ) : (
                <BsShieldFillCheck fontSize={20} color="#135fc2" />
              )}
            </Box>
          </Box>
          <Box sx={{ mb: 1.5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography>{t('Poziom trudności')}:&nbsp;</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{item.level}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography>{t('Autor')}:&nbsp;</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{item.autor}</Typography>
          </Box>
        </CardContent>
      </Card>
    </button>
  );
};

export default Cards;
