import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';

import { Box, Grid, List, ListItem, Typography } from '@mui/material';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { styled } from '@mui/material/styles';
import { FaPiggyBank } from 'react-icons/fa';
import { BsShieldFillCheck } from 'react-icons/bs';

const Analytics = () => {
  const { t } = useTranslation();

  // function generate(element: React.ReactElement) {
  //   return [
  //     'Skrypt rozmowy Finanse 1.0',

  //     'Skrypt rozmowy Finanse 1.1',

  //     'Skrypt rozmowy Finanse 1.2',

  //     'Skrypt rozmowy Finanse 1.3',

  //     'Skrypt rozmowy Finanse 1.4',

  //     'Skrypt rozmowy Ubezpieczenie 1.0',

  //     'Skrypt rozmowy Ubezpieczenie 1.1',

  //     'Skrypt rozmowy Ubezpieczenie 1.2',

  //     'Skrypt rozmowy Ubezpieczenie 1.3',

  //     'Skrypt rozmowy Ubezpieczenie 1.4',
  //   ].map((value) =>
  //     React.cloneElement(element, {
  //       key: value,
  //     }),
  //   );
  // }
  const arrayFinanse = [
    'Skrypt rozmowy Finanse 1.0',

    'Skrypt rozmowy Finanse 1.1',

    'Skrypt rozmowy Finanse 1.2',

    'Skrypt rozmowy Finanse 1.3',

    'Skrypt rozmowy Finanse 1.4',
  ];

  const arrayUbezpieczenie = [
    'Skrypt rozmowy Ubezpieczenie 1.0',

    'Skrypt rozmowy Ubezpieczenie 1.1',

    'Skrypt rozmowy Ubezpieczenie 1.2',

    'Skrypt rozmowy Ubezpieczenie 1.3',

    'Skrypt rozmowy Ubezpieczenie 1.4',
  ];
  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>{t('Skrypty rozmów')}</div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <Box sx={{ width: '100%' }}>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ mt: 4, mb: 2, ml: 3, mr: 3 }} variant="h6" component="div">
                    Skrypt rozmowy sprzedażowej to, najprościej mówiąc, spisany plan rozmowy, jaką zamierza się wykonać.
                    Składa się z listy pytań oraz schematów potencjalnych odpowiedzi. Przydaje się on najczęściej
                    podczas kontaktu telefonicznego, ale bywa także pomocny podczas bezpośrednich spotkań z klientami.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <List sx={{ ml: 3, mr: 3 }}>
                      {arrayFinanse.map((item) => (
                        <ListItem>
                          {/* <ListItemIcon>
                          </ListItemIcon> */}
                          {/* <ListItemText primary="Single-line item" /> */}
                          <FaPiggyBank fontSize={25} color="blue" />
                          &nbsp;&nbsp;
                          <a
                            style={{ color: '#024a7c' }}
                            href="https://www.orimi.com/pdf-test.pdf"
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                        </ListItem>
                      ))}
                    </List>
                    <List sx={{ ml: 3, mr: 3 }}>
                      {arrayUbezpieczenie.map((item) => (
                        <ListItem>
                          {/* <ListItemIcon>
                          </ListItemIcon> */}
                          {/* <ListItemText primary="Single-line item" /> */}
                          <BsShieldFillCheck fontSize={20} color="blue" />
                          &nbsp;&nbsp;
                          <a
                            style={{ color: '#024a7c' }}
                            href="https://www.orimi.com/pdf-test.pdf"
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Box>
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default Analytics;
