import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateFilters } from 'Utils/common';

import * as actionTypes from 'Store/requests/actionTypes';

type RequestsState = {
  orders: Array<{
    customer: {
      company_name: string;
      first_name: string;
      id: number;
      last_name: string;
      type: number;
      nip: number;
      email: string;
      phone: number;
      source: number;
    };
    id: number;
    hall: number;
    event: number;
    estimated_price: number;
    from: string;
    to: string;
    note: string;
    order_value: number;
    status: number;
    substatus: number;
    people: number;
    updated_at: string;
    updated_by: {
      first_name: string;
      id: number;
      last_name: string;
      user_id: number;
    };
    created_at: string;
    created_by: {
      first_name: string;
      id: number;
      last_name: string;
      user_id: number;
    };
    additional_cost: number;
    custom_reply: boolean;
    reply: string;
  }>;
  total: number;
  total_value: number;
  currentPage: number;
  filters: {};
  filtersParams: string;
  filtersActive: boolean;
  isReady: boolean;
  filtersOpen: boolean;
};

type initialFiltersType = {
  event: {
    label: string;
    value: string;
  };
  hall: {
    label: string;
    value: string;
  };
  term_from: {
    label: string;
    value: string;
  };
  term_to: {
    label: string;
    value: string;
  };
  created_at_from: {
    label: string;
    value: string;
  };
  created_at_to: {
    label: string;
    value: string;
  };
  status: {
    label: string;
    value: string;
  };
  email: {
    label: string;
    value: string;
  };
  phone: {
    label: string;
    value: string;
  };
  last_name: {
    label: string;
    value: string;
  };
  company_name: {
    label: string;
    value: string;
  };
  nip: {
    label: string;
    value: string;
  };
  created_by: {
    label: string;
    value: string;
  };
  source: {
    label: string;
    value: string;
  };
  closed: {
    label: string;
    value: string;
  };
};

const initialFilters: initialFiltersType = {
  event: {
    label: 'Event',
    value: '',
  },
  hall: {
    label: 'Hall',
    value: '',
  },
  term_from: {
    label: 'Data od',
    value: '',
  },
  term_to: {
    label: 'Data do',
    value: '',
  },
  created_at_from: {
    label: 'Created at from',
    value: '',
  },
  created_at_to: {
    label: 'Created at to',
    value: '',
  },
  status: {
    label: 'Status',
    value: '',
  },
  email: {
    label: 'Email',
    value: '',
  },
  phone: {
    label: 'Phone',
    value: '',
  },
  last_name: {
    label: 'Last name',
    value: '',
  },
  company_name: {
    label: 'Company name',
    value: '',
  },
  nip: {
    label: 'NIP',
    value: '',
  },
  created_by: {
    label: 'Created by',
    value: '',
  },
  source: {
    label: 'Source',
    value: '',
  },
  closed: {
    label: 'Closed requests only',
    value: '',
  },
};

const initialState: RequestsState = {
  orders: [],
  total: 0,
  total_value: 0,
  currentPage: 1,
  filters: { ...initialFilters },
  filtersParams: '',
  filtersActive: false,
  isReady: false,
  filtersOpen: false,
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.TOGGLE_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        filtersOpen: {
          $set: filtersOpen,
        },
      });
    }
    case actionTypes.SET_REQUESTS: {
      const { orders, total_value, total } = action.payload;

      return update(state, {
        orders: {
          $set: orders,
        },
        total_value: {
          $set: total_value,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        currentPage: {
          $set: currentPage,
        },
      });
    }
    case actionTypes.SET_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some(
        (key) => updatedFilters[key].value && !updatedFilters[key].skipActive,
      );

      return update(state, {
        filters: {
          $set: updatedFilters,
        },
        filtersActive: {
          $set: filtersActive,
        },
      });
    }
    case actionTypes.SET_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        filtersParams: {
          $set: filtersParams,
        },
      });
    }
    case actionTypes.RESET_FILTERS: {
      return update(state, {
        filters: {
          $set: initialFilters,
        },
        filtersActive: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_REQUESTS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_REQUESTS: {
      return update(state, {
        $set: initialState,
      });
    }
    default:
      return state;
  }
};
