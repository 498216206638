import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { cnb } from 'cnbuilder';

import TableCell from 'Components/@Shared/TableCell';
import ActionsDropdown from 'Containers/Analytics/components/AnalyticsTable/components/ActionsDropdown';

import { store } from 'Store/store';

import styles from 'Components/@Styles/Table.module.scss';
import { GROUPS_ENDPOINTS } from 'Constants/endpoints';
import API from 'Lib/axios';
import { useEffect, useState } from 'react';
import { ETAP_STATUS } from 'Constants/sourcing';
import { formatLocalFromWithoutSeconds } from 'Utils/common';
import Icon from 'Components/@Base/Icon';
import { GoSearch } from 'react-icons/go';
import { useAppSelector } from 'Hooks/redux-hooks';

let TABLE_STRUCTURE: any = {
  name: {
    header: 'Nazwa',
    className: 'col-lg',
    useEllipsis: true,
  },
  count: {
    header: 'Ilość',
    className: 'col-lg',
    useEllipsis: true,
  },
};

type AnalyticsTableProps = {
  data: any;
  type: string;
  usersData?: any;
};

let renderElementTable = null;

const AnalyticsTable = ({ data, type, usersData }: AnalyticsTableProps) => {
  const { t } = useTranslation();

  const knownKeys = ['count', 'reports'];

  function findEmailById(userId) {
    const user = usersData.find((userItem) => userItem.id === userId);
    return user ? user.email : null; // Возвращаем email пользователя или null, если пользователь не найден
  }

  // console.log('data', data);

  switch (type) {
    case 'user':
      TABLE_STRUCTURE = {
        name: {
          header: 'Użytkownik',
          className: 'col-lg',
          useEllipsis: true,
        },
        count: {
          header: 'Ilość',
          className: 'col-lg',
          useEllipsis: true,
        },
        button: {
          className: styles.TableCell_Action,
        },
      };
      renderElementTable = (row, unknownKey, i) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.name.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.name.header)}
            useEllipsis={TABLE_STRUCTURE.name.useEllipsis}
          >
            <span>{row[unknownKey]}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.count.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.count.header)}
            useEllipsis={TABLE_STRUCTURE.count.useEllipsis}
          >
            <span>{row.count}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.button.className, 'text-center')}
            cellHeader={t(TABLE_STRUCTURE.button.header)}
          >
            <Accordion.Toggle className={cnb(styles.button_action)} eventKey={`${i}`}>
              <Icon>
                <GoSearch />
              </Icon>
            </Accordion.Toggle>
          </TableCell>
        </div>
      );
      break;
    case 'perc':
      TABLE_STRUCTURE = {
        name: {
          header: 'Wskaźnik ukończenia',
          className: 'col-lg',
          useEllipsis: true,
        },
        count: {
          header: 'Ilość',
          className: 'col-lg',
          useEllipsis: true,
        },
        button: {
          className: styles.TableCell_Action,
        },
      };
      renderElementTable = (row, unknownKey, i) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.name.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.name.header)}
            useEllipsis={TABLE_STRUCTURE.name.useEllipsis}
          >
            <span>
              {row[unknownKey]}
              {` %`}
            </span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.count.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.count.header)}
            useEllipsis={TABLE_STRUCTURE.count.useEllipsis}
          >
            <span>{row.count}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.button.className, 'text-center')}
            cellHeader={t(TABLE_STRUCTURE.button.header)}
          >
            <Accordion.Toggle className={cnb(styles.button_action)} eventKey={`${i}`}>
              <Icon>
                <GoSearch />
              </Icon>
            </Accordion.Toggle>
          </TableCell>
        </div>
      );
      break;
    case 'schema':
      TABLE_STRUCTURE = {
        name: {
          header: 'Nazwa',
          className: 'col-lg',
          useEllipsis: true,
        },
        count: {
          header: 'Ilość',
          className: 'col-lg',
          useEllipsis: true,
        },
        button: {
          className: styles.TableCell_Action,
        },
      };
      renderElementTable = (row, unknownKey, i) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.name.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.name.header)}
            useEllipsis={TABLE_STRUCTURE.name.useEllipsis}
          >
            <span>{row[unknownKey]}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.count.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.count.header)}
            useEllipsis={TABLE_STRUCTURE.count.useEllipsis}
          >
            <span>{row.count}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.button.className, 'text-center')}
            cellHeader={t(TABLE_STRUCTURE.button.header)}
          >
            <Accordion.Toggle className={cnb(styles.button_action)} eventKey={`${i}`}>
              <Icon>
                <GoSearch />
              </Icon>
            </Accordion.Toggle>
          </TableCell>
        </div>
      );
      break;
    case 'stage':
      TABLE_STRUCTURE = {
        name: {
          header: 'Etap',
          className: 'col-lg',
          useEllipsis: true,
        },
        count: {
          header: 'Ilość',
          className: 'col-lg',
          useEllipsis: true,
        },
        button: {
          className: styles.TableCell_Action,
        },
      };
      renderElementTable = (row, unknownKey, i) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.name.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.name.header)}
            useEllipsis={TABLE_STRUCTURE.name.useEllipsis}
          >
            <span>{ETAP_STATUS[row[unknownKey]]}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.count.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.count.header)}
            useEllipsis={TABLE_STRUCTURE.count.useEllipsis}
          >
            <span>{row.count}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.button.className, 'text-center')}
            cellHeader={t(TABLE_STRUCTURE.button.header)}
          >
            <Accordion.Toggle className={cnb(styles.button_action)} eventKey={`${i}`}>
              <Icon>
                <GoSearch />
              </Icon>
            </Accordion.Toggle>
          </TableCell>
        </div>
      );
      break;
    case 'time':
      TABLE_STRUCTURE = {
        name: {
          header: 'Czas rozmowy (sekundy)',
          className: 'col-lg',
          useEllipsis: true,
        },
        count: {
          header: 'Ilość',
          className: 'col-lg',
          useEllipsis: true,
        },
        button: {
          className: styles.TableCell_Action,
        },
      };
      renderElementTable = (row, unknownKey, i) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.name.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.name.header)}
            useEllipsis={TABLE_STRUCTURE.name.useEllipsis}
          >
            <span>{row[unknownKey]}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.count.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.count.header)}
            useEllipsis={TABLE_STRUCTURE.count.useEllipsis}
          >
            <span>{row.count}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.button.className, 'text-center')}
            cellHeader={t(TABLE_STRUCTURE.button.header)}
          >
            <Accordion.Toggle className={cnb(styles.button_action)} eventKey={`${i}`}>
              <Icon>
                <GoSearch />
              </Icon>
            </Accordion.Toggle>
          </TableCell>
        </div>
      );
      break;
    case 'success':
      TABLE_STRUCTURE = {
        perc_left: {
          header: 'Pozostały procent',
          className: 'col-lg',
          useEllipsis: true,
        },
        stage: {
          header: 'Etap',
          className: 'col-lg',
          useEllipsis: true,
        },
        steps_completed: {
          header: 'Kroki zakończone',
          className: 'col-lg',
          useEllipsis: true,
        },
        time: {
          header: 'Czas rozmowy (sekundy)',
          className: 'col-lg',
          useEllipsis: true,
        },
      };
      renderElementTable = (row) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.perc_left.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.perc_left.header)}
            useEllipsis={TABLE_STRUCTURE.perc_left.useEllipsis}
          >
            <span>
              {row.perc_left}
              {'%'}
            </span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.stage.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.stage.header)}
            useEllipsis={TABLE_STRUCTURE.stage.useEllipsis}
          >
            <span>{ETAP_STATUS[row.stage]}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.steps_completed.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.steps_completed.header)}
            useEllipsis={TABLE_STRUCTURE.steps_completed.useEllipsis}
          >
            <span>{row.steps_completed}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.time.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.time.header)}
            useEllipsis={TABLE_STRUCTURE.time.useEllipsis}
          >
            <span>{row.time}</span>
          </TableCell>
        </div>
      );
      break;
    case 'failure':
      TABLE_STRUCTURE = {
        perc_left: {
          header: 'Pozostały procent',
          className: 'col-lg',
          useEllipsis: true,
        },
        stage: {
          header: 'Etap',
          className: 'col-lg',
          useEllipsis: true,
        },
        steps_completed: {
          header: 'Kroki zakończone',
          className: 'col-lg',
          useEllipsis: true,
        },
        time: {
          header: 'Czas rozmowy (sekundy)',
          className: 'col-lg',
          useEllipsis: true,
        },
      };
      renderElementTable = (row) => (
        <div className={styles.ContentRow}>
          <TableCell
            className={cnb(TABLE_STRUCTURE.perc_left.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.perc_left.header)}
            useEllipsis={TABLE_STRUCTURE.perc_left.useEllipsis}
          >
            <span>
              {row.perc_left}
              {'%'}
            </span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.stage.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.stage.header)}
            useEllipsis={TABLE_STRUCTURE.stage.useEllipsis}
          >
            <span>{ETAP_STATUS[row.stage]}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.steps_completed.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.steps_completed.header)}
            useEllipsis={TABLE_STRUCTURE.steps_completed.useEllipsis}
          >
            <span>{row.steps_completed}</span>
          </TableCell>
          <TableCell
            className={cnb(TABLE_STRUCTURE.time.className, styles.TableCell)}
            cellHeader={t(TABLE_STRUCTURE.time.header)}
            useEllipsis={TABLE_STRUCTURE.time.useEllipsis}
          >
            <span>{row.time}</span>
          </TableCell>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="mt-3 w-100">
      <ReactTooltip className={styles.Tooltip} place="top" multiline />
      <div className={cnb(styles.TableRow, styles.TableRow__header)}>
        <div className="row">
          {Object.keys(TABLE_STRUCTURE).map((key) => (
            <TableCell
              key={key}
              className={TABLE_STRUCTURE[key].className}
              onClick={
                typeof TABLE_STRUCTURE[key].onClick === 'function'
                  ? () => TABLE_STRUCTURE[key].onClick(store)
                  : undefined
              }
            >
              {t(TABLE_STRUCTURE[key].header)}
            </TableCell>
          ))}
        </div>
      </div>
      <Accordion>
        {data.map((row, i) => {
          const allKeys = Object.keys(row);

          const unknownKey = allKeys.find((key) => !knownKeys.includes(key));
          return (
            <Card key={row.id} className={styles.TableCard}>
              {renderElementTable(row, unknownKey, i)}
              {type === 'success' || type === 'failure' ? null : (
                <Accordion.Collapse eventKey={`${i}`}>
                  <div className={styles.Panel_Content}>
                    <div className={styles.Panel_left}>
                      {row.reports.map((report, index) => (
                        <div
                          className={cnb(styles.Panel_Item_Analytics)}
                          style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0' }}
                        >
                          {type === 'user' ? (
                            <div className="d-flex flex-row mr-2 col-lg p-1">
                              <span>
                                {t('#')}
                                &nbsp;
                              </span>
                              <span>{index + 1}</span>
                            </div>
                          ) : (
                            <div className="d-flex flex-row mr-2 col-lg p-1">
                              <span>
                                {t('User')}
                                {':'}
                                &nbsp;
                              </span>
                              <span>{findEmailById(report.user_id)}</span>
                            </div>
                          )}
                          <div className="d-flex flex-row mr-2 col-lg">
                            <span>
                              {t('Ukończony')}
                              {':'}
                              &nbsp;
                            </span>
                            <span>{report.completed ? 'Tak' : 'Nie'}</span>
                          </div>
                          <div className="d-flex flex-row mr-2 col-lg">
                            <span>
                              {t('Kroki zakończone')}
                              {':'}
                              &nbsp;
                            </span>
                            <span>{report.steps_completed ? report.steps_completed : '-'}</span>
                          </div>
                          {type === 'time' ? null : (
                            <div className="d-flex flex-row mr-2 col-lg">
                              <span>
                                {t('Czas rozmowy (sekundy)')}
                                {':'}
                                &nbsp;
                              </span>
                              <span>{report.time ? report.time : '-'}</span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Accordion.Collapse>
              )}
            </Card>
          );
        })}
      </Accordion>
    </div>
  );
};

AnalyticsTable.defaultProps = {
  usersData: [],
};

export default AnalyticsTable;
