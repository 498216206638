export const SET_TRANSLATION_LOCALE = '@@translations/SET_TRANSLATION_LOCALE';
export const SET_TRANSLATION = '@@translations/SET_TRANSLATION';
export const SET_TRANSLATION_READY = '@@translations/SET_TRANSLATION_READY';
export const SET_TRANSLATION_CURRENT_PAGE = '@@translations/SET_TRANSLATION_CURRENT_PAGE';
export const SET_TRANSLATION_FILTERS = '@@translations/SET_TRANSLATION_FILTERS';
export const SET_TRANSLATION_FILTERS_PARAMS = '@@translations/SET_TRANSLATION_FILTERS_PARAMS';
export const SET_TRANSLATION_FILTERS_READY = '@@translations/SET_TRANSLATION_FILTERS_READY';
export const RESET_TRANSLATION_FILTERS = '@@translations/RESET_TRANSLATION_FILTERS';
export const TOGGLE_TRANSLATION_FILTERS_SIDEBAR = '@@translations/TOGGLE_TRANSLATION_FILTERS_SIDEBAR';
export const CLEAR_TRANSLATION = '@@translations/CLEAR_TRANSLATION';
export const SET_TRANSLATIONS = '@@translations/SET_TRANSLATIONS';
export const SET_TRANSLATIONS_READY = '@@translations/SET_TRANSLATIONS_READY';
export const CLEAR_TRANSLATIONS = '@@translations/CLEAR_TRANSLATIONS';
