import update from 'immutability-helper';
import * as actionTypes from 'Store/coordinatorReports/actionTypes';

type ReportsState = {
  reports: Array<{
    id: number;
    perc_left: number;
    stage: string;
    steps_completed: number;
    success: boolean;
    time: number;
  }>;
  report: Array<{
    id: number;
    perc_left: number;
    stage: string;
    steps_completed: number;
    success: boolean;
    time: number;
  }>;
  isReady: boolean;
  isReadyReport: boolean;
  // total: number;
};

const initialState: ReportsState = {
  reports: [],
  report: [],
  isReady: false,
  isReadyReport: false,
  // total: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REPORTS: {
      const { reports } = action.payload;

      return update(state, {
        reports: {
          $set: reports,
        },
        // total: {
        //   $set: total,
        // },
      });
    }
    case actionTypes.SET_REPORTS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_REPORTS: {
      return update(state, {
        reports: {
          $set: [],
        },
        isReady: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_REPORT: {
      const { reports } = action.payload;

      return update(state, {
        report: {
          $set: reports,
        },
        // total: {
        //   $set: total,
        // },
      });
    }
    case actionTypes.SET_REPORT_READY: {
      return update(state, {
        isReadyReport: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_REPORT: {
      return update(state, {
        report: {
          $set: [],
        },
        isReadyReport: {
          $set: false,
        },
      });
    }
    default:
      return state;
  }
};
