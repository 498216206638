import DotLoader from 'Components/@Base/DotLoader';

import styles from './Loader.module.scss';

type LoaderProps = {
  minHeight?: string;
};

const Loader = ({ minHeight }: LoaderProps) => {
  const InnerLoader = DotLoader;

  return (
    <div className={styles.Container} style={{ minHeight }}>
      <InnerLoader className={styles.Loader} />
    </div>
  );
};

Loader.defaultProps = {
  minHeight: '100%',
};

export default Loader;
