import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { COOKIE_ITEMS_PER_PAGE } from 'Utils/cookies';

import * as actionTypes from 'Store/app/actionTypes';

type AppState = {
  appLoading: boolean;
  sidebarOpen: boolean;
  itemsPerPage: number;
  languages: Array<{
    id: number;
    locale: string;
    name: string;
  }>;
  customer_statuses: Array<string | number>;
  request_statuses: Array<string | number>;
  order_statuses: Array<string | number>;
  accounts: {
    [key: string]: string;
  };
  summary: {
    facebook?: {
      today: number;
      monthly: number;
    };
    website?: {
      today: number;
      monthly: number;
    };
  };
  types: Array<string | number>;
  sources: Array<string | number>;
  halls: {
    [key: string]: string;
  };
  customers: {
    [key: string]: string;
  };
  events: {
    [key: string]: string;
  };
  area_codes: {
    [key: string]: string;
  };
  pay_statuses: Array<string | number>;
  substatuses: {
    [key: string]: string;
  };
  database_statuses: {
    [key: string]: string;
  };
  lead_statuses: {
    [key: string]: string;
  };
  databases: {
    [key: string]: string;
  };
  provinces: {
    [key: string]: string;
  };
  legal_forms: {
    [key: string]: string;
  };
  pkd_codes: {
    [key: string]: string;
  };
};

const initialState: AppState = {
  appLoading: true,
  sidebarOpen: false,
  itemsPerPage: Number(window.localStorage.getItem(COOKIE_ITEMS_PER_PAGE)) || 15,
  languages: [],
  customer_statuses: [],
  accounts: {},
  summary: {},
  types: [],
  sources: [],
  halls: {},
  customers: {},
  request_statuses: [],
  order_statuses: [],
  events: {},
  area_codes: {},
  pay_statuses: [],
  substatuses: {},
  database_statuses: {},
  lead_statuses: {},
  databases: {},
  provinces: {},
  legal_forms: {},
  pkd_codes: {},
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SET_IS_LOADING: {
      const { appLoading } = action.payload;

      return update(state, {
        appLoading: {
          $set: appLoading,
        },
      });
    }
    case actionTypes.TOGGLE_SIDEBAR: {
      const { sidebarOpen } = action.payload;

      return update(state, {
        sidebarOpen: {
          $set: sidebarOpen,
        },
      });
    }
    case actionTypes.SET_ITEMS_PER_PAGE: {
      const { itemsPerPage } = action.payload;

      return update(state, {
        itemsPerPage: {
          $set: itemsPerPage,
        },
      });
    }
    case actionTypes.SET_LANGUAGES: {
      const { languages } = action.payload;

      return update(state, {
        languages: {
          $set: languages,
        },
      });
    }
    case actionTypes.SET_CUSTOMER_STATUSES: {
      const { customer_statuses } = action.payload;

      return update(state, {
        customer_statuses: {
          $set: customer_statuses,
        },
      });
    }
    case actionTypes.SET_ACCOUNTS: {
      const { accounts } = action.payload;

      return update(state, {
        accounts: {
          $set: accounts,
        },
      });
    }
    case actionTypes.SET_SUMMARY: {
      const { summary } = action.payload;

      return update(state, {
        summary: {
          $set: summary,
        },
      });
    }
    case actionTypes.SET_CUSTOMER_TYPES: {
      const { types } = action.payload;

      return update(state, {
        types: {
          $set: types,
        },
      });
    }
    case actionTypes.SET_CUSTOMER_SOURCES: {
      const { sources } = action.payload;

      return update(state, {
        sources: {
          $set: sources,
        },
      });
    }
    case actionTypes.SET_HALLS: {
      const { halls } = action.payload;

      return update(state, {
        halls: {
          $set: halls,
        },
      });
    }
    case actionTypes.SET_CUSTOMERS: {
      const { customers } = action.payload;

      return update(state, {
        customers: {
          $set: customers,
        },
      });
    }
    case actionTypes.SET_REQUEST_STATUSES: {
      const { request_statuses } = action.payload;

      return update(state, {
        request_statuses: {
          $set: request_statuses,
        },
      });
    }
    case actionTypes.SET_ORDER_STATUSES: {
      const { order_statuses } = action.payload;

      return update(state, {
        order_statuses: {
          $set: order_statuses,
        },
      });
    }
    case actionTypes.SET_EVENTS: {
      const { events } = action.payload;

      return update(state, {
        events: {
          $set: events,
        },
      });
    }
    case actionTypes.SET_AREA_CODES: {
      const { area_codes } = action.payload;

      return update(state, {
        area_codes: {
          $set: area_codes,
        },
      });
    }
    case actionTypes.SET_PAY_STATUSES: {
      const { pay_statuses } = action.payload;

      return update(state, {
        pay_statuses: {
          $set: pay_statuses,
        },
      });
    }
    case actionTypes.SET_SUB_STATUS_NOT_INTERESTED: {
      const { substatuses } = action.payload;

      return update(state, {
        substatuses: {
          $set: substatuses,
        },
      });
    }
    case actionTypes.SET_DATABASE_STATUSES: {
      const { database_statuses } = action.payload;

      return update(state, {
        database_statuses: {
          $set: database_statuses,
        },
      });
    }
    case actionTypes.SET_LEAD_STATUSES: {
      const { lead_statuses } = action.payload;

      return update(state, {
        lead_statuses: {
          $set: lead_statuses,
        },
      });
    }
    case actionTypes.SET_DATABASES: {
      const { databases } = action.payload;

      return update(state, {
        databases: {
          $set: databases,
        },
      });
    }
    case actionTypes.SET_PROVINCES: {
      const { provinces } = action.payload;

      return update(state, {
        provinces: {
          $set: provinces,
        },
      });
    }
    case actionTypes.SET_LEGAL_FORMS: {
      const { legal_forms } = action.payload;

      return update(state, {
        legal_forms: {
          $set: legal_forms,
        },
      });
    }
    case actionTypes.SET_PKD_CODES: {
      const { pkd_codes } = action.payload;

      return update(state, {
        pkd_codes: {
          $set: pkd_codes,
        },
      });
    }
    default:
      return state;
  }
};
