import ReduxToastr from 'react-redux-toastr';

const Toastr = () => (
  <ReduxToastr
    timeOut={6000}
    newestOnTop
    preventDuplicates
    position="top-right"
    transitionIn="bounceInDown"
    transitionOut="fadeOut"
    progressBar
    closeOnToastrClick
  />
);

export default Toastr;
