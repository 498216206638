import React from 'react';
import { cnb } from 'cnbuilder';
import { Spinner } from 'react-bootstrap';

import styles from './Button.module.scss';

type ButtonProps = {
  className?: string;
  label: string;
  type: 'button' | 'submit';
  buttonType?: 'primary' | 'secondary';
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button = ({ className, label, type, buttonType, isSubmitting, isDisabled, onClick, ...rest }: ButtonProps) => {
  return (
    <button
      className={cnb(
        className,
        styles.Button,
        buttonType && styles[`Button__${buttonType}`],
        isDisabled && styles[`Button__isDisabled__${buttonType}`],
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      {...rest}
    >
      {isSubmitting ? (
        <Spinner className={styles.Button__loader} animation="border" />
      ) : (
        <span className={styles.Label}>{label}</span>
      )}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  buttonType: 'primary',
  isSubmitting: false,
  isDisabled: false,
  onClick: undefined,
};

export default Button;
