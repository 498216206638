import { cnb } from 'cnbuilder';

type IconProps = {
  className?: string;
  children: JSX.Element;
};

const Icon = ({ className, children }: IconProps) => (
  <figure className={cnb(className, 'd-inline-flex align-items-center justify-content-center mb-0')}>{children}</figure>
);

Icon.defaultProps = {
  className: '',
};

export default Icon;
