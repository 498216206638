import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { TICKET_STATUSES } from 'Constants/ticket';

import { updateFilters } from 'Utils/common';

import * as actionTypes from 'Store/tickets/actionTypes';

type TicketsState = {
  currentTicket: {
    isReady: boolean;
    ticket_messages?: Array<{
      id: number;
      ticket_id: number;
      content: string;
      base64: string;
      created_at: string;
      owned_by: {
        id: number;
        user_id: number;
        first_name: string;
      };
    }>;
    status?: number;
    subject?: string;
    owned_by?: {
      email?: string;
    };
  };
  ticketList: {
    tickets: Array<{ id: number; code: string; subject: string; status: number; created_at: string }>;
    ticketsAdmin: Array<{ id: number; code: string; subject: string; status: number; created_at: string }>;
    total: number;
    currentPage: number;
    filters: {};
    filtersParams: string;
    filtersActive: boolean;
    isReady: boolean;
    filtersOpen: boolean;
    owned_by?: {
      email?: string;
    };
  };
  statuses: { [key: number]: string };
};

type initialFiltersType = {
  email: {
    label: string;
    value: string;
  };
  code: {
    label: string;
    value: string;
  };
  status: {
    label: string;
    value: string;
  };
  date_from: {
    label: string;
    value: string;
  };
  date_to: {
    label: string;
    value: string;
  };
  date_sort: {
    label: string;
    value: string;
    skipActive: boolean;
  };
};

const initialFilters: initialFiltersType = {
  email: {
    label: 'Email',
    value: '',
  },
  code: {
    label: 'Zgłoszenie id',
    value: '',
  },
  status: {
    label: 'Status',
    value: '',
  },
  date_from: {
    label: 'Data od',
    value: '',
  },
  date_to: {
    label: 'Data do',
    value: '',
  },
  date_sort: {
    label: '[DateSort]',
    value: 'desc',
    skipActive: true,
  },
};

const initialState: TicketsState = {
  currentTicket: {
    isReady: false,
  },
  ticketList: {
    tickets: [],
    ticketsAdmin: [],
    total: 0,
    currentPage: 1,
    filters: { ...initialFilters },
    filtersParams: '',
    filtersActive: false,
    isReady: false,
    filtersOpen: false,
  },
  statuses: TICKET_STATUSES,
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SET_TICKET: {
      const { ticket } = action.payload;

      return update(state, {
        currentTicket: {
          $merge: ticket,
        },
      });
    }
    case actionTypes.SET_TICKET_READY: {
      return update(state, {
        currentTicket: {
          $merge: {
            isReady: true,
          },
        },
      });
    }
    case actionTypes.CLEAR_TICKET: {
      return update(state, {
        currentTicket: {
          $set: initialState.currentTicket,
        },
      });
    }
    case actionTypes.TOGGLE_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        ticketList: {
          filtersOpen: {
            $set: filtersOpen,
          },
        },
      });
    }
    case actionTypes.SET_TICKETS: {
      const { tickets, total } = action.payload;

      return update(state, {
        ticketList: {
          tickets: {
            $set: tickets,
          },
          total: {
            $set: total,
          },
        },
      });
    }
    case actionTypes.SET_TICKETS_ADMIN: {
      const { ticketsAdmin, total } = action.payload;

      return update(state, {
        ticketList: {
          ticketsAdmin: {
            $set: ticketsAdmin,
          },
          total: {
            $set: total,
          },
        },
      });
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        ticketList: {
          currentPage: {
            $set: currentPage,
          },
        },
      });
    }
    case actionTypes.SET_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.ticketList.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some(
        (key) => updatedFilters[key].value && !updatedFilters[key].skipActive,
      );

      return update(state, {
        ticketList: {
          filters: {
            $set: updatedFilters,
          },
          filtersActive: {
            $set: filtersActive,
          },
        },
      });
    }
    case actionTypes.SET_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        ticketList: {
          filtersParams: {
            $set: filtersParams,
          },
        },
      });
    }
    case actionTypes.RESET_FILTERS: {
      return update(state, {
        ticketList: {
          filters: {
            $set: initialFilters,
          },
          filtersActive: {
            $set: false,
          },
        },
      });
    }
    case actionTypes.SET_TICKETS_READY: {
      return update(state, {
        ticketList: {
          isReady: {
            $set: true,
          },
        },
      });
    }
    case actionTypes.CLEAR_TICKETS: {
      return update(state, {
        ticketList: {
          $set: initialState.ticketList,
        },
      });
    }
    default:
      return state;
  }
};
