import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import { MdArrowBack } from 'react-icons/all';
import { cnb } from 'cnbuilder';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';
import usePrevious from 'Hooks/use-previous';

import { toggleSidebarAction } from 'Store/app/actions';

import SIDEBAR_LINKS from 'Utils/sidebar-links';
import { ROLE_TYPE_COORDINATOR } from 'Constants';

import MenuItem from 'Layouts/WithSidebarLayout/components/Sidebar/components/Menu/components/MenuItem';

import styles from './Menu.module.scss';

const setMenuStyle = (activeLevel: number, prevActiveLevel: number | null) => {
  if ((activeLevel === 0 && prevActiveLevel === 0) || (activeLevel === 1 && prevActiveLevel === 0)) {
    return false;
  }

  return activeLevel > 1 || !(activeLevel === 2 && prevActiveLevel === 2);
};

const isSubmenuActive = (name: string | undefined, pathname: string) => {
  return name && pathname.includes(name);
};

const findMenu = (pathname: string) => {
  let menu = null;
  for (let i = 0, len = SIDEBAR_LINKS.length; i < len; i++) {
    const found = SIDEBAR_LINKS[i].children.find(
      (submenu) => submenu.url === pathname || (submenu.url !== '/' && pathname.startsWith(submenu.url ?? '')),
    );

    if (found) {
      menu = SIDEBAR_LINKS[i];
    }
  }

  return menu;
};

const Menu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { sidebarOpen } = useAppSelector((state) => state.app);
  const { notifications } = useAppSelector((state) => state.menuNotifications);
  const { role } = useAppSelector((state) => state.user);
  const submenu = findMenu(pathname);
  const [activeMenu, setActiveMenu] = useState<undefined | string>(submenu?.id ?? 'main');
  const [activeLevel, setActiveLevel] = useState(submenu?.level ?? 0);
  const prevActiveLevel = usePrevious(activeLevel);
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const isCoordinator = role === ROLE_TYPE_COORDINATOR;

  const handleCloseOnClick = () => {
    dispatch(toggleSidebarAction(false));
  };

  useEffect(() => {
    if (isDesktop && sidebarOpen) {
      dispatch(toggleSidebarAction(false));
    }
  }, [dispatch, isDesktop, sidebarOpen]);

  useEffect(() => {
    const menu = SIDEBAR_LINKS.find((o) => o.menuName === activeMenu);

    if (menu) {
      setActiveLevel(menu.level);
    }
  }, [activeMenu]);

  return (
    <div className={cnb(styles.Menu, setMenuStyle(activeLevel, prevActiveLevel) && styles[`Menu_${activeMenu}`])}>
      {SIDEBAR_LINKS.map((menu) => {
        if (menu.isCoordinator && !isCoordinator) {
          return null;
        }

        return (
          <CSSTransition
            key={menu.menuName}
            in={activeMenu === menu.menuName}
            timeout={500}
            classNames={menu.classNames}
            mountOnEnter
            // unmountOnExit
          >
            <div className={styles.Menu__items}>
              {(menu.menuName === 'admin' || menu.menuName === 'koordynator') && (
                <MenuItem label={t('Wróć')} leftIcon={MdArrowBack} onGoToMenu={() => setActiveMenu('main')} border />
              )}

              {menu.children.map((menuItem) => {
                if (menuItem.isCoordinator && !isCoordinator) {
                  return null;
                }

                return (
                  <MenuItem
                    key={menuItem.id}
                    className={cnb(isSubmenuActive(menuItem.submenuName, pathname) && styles.Menu__item_isActive)}
                    label={t(menuItem.label)}
                    leftIcon={menuItem.leftIcon}
                    isImage={menuItem.isImage}
                    rightIcon={menuItem.rightIcon}
                    notification={notifications[menuItem.id]}
                    image={menuItem.image}
                    url={menuItem.url}
                    exact={menuItem.exact}
                    onGoToMenu={menuItem.submenuName ? () => setActiveMenu(menuItem.submenuName) : undefined}
                    onClick={handleCloseOnClick}
                    border={menuItem.border}
                    url_newPage={menuItem.url_newPage}
                  />
                );
              })}
            </div>
          </CSSTransition>
        );
      })}
    </div>
  );
};

export default Menu;
