import 'Lib/i18n';

import 'bootstrap/dist/css/bootstrap.css';
import 'Styles/global.scss';

import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Suspense } from 'react';
import { Provider } from 'react-redux';

import history from 'Lib/history';

import { store } from 'Store/store';

import PageLoader from 'Components/@Shared/PageLoader';

import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Suspense fallback={<PageLoader />}>
        <App />
      </Suspense>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
