import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import { reducer as app } from 'Store/app/reducer';
import { reducer as user } from 'Store/user/reducer';
import { reducer as modal } from 'Store/modal/reducer';
import { reducer as menuNotifications } from 'Store/menuNotifications/reducer';
import { reducer as translations } from 'Store/translations/reducer';
import { reducer as settings } from 'Store/settings/reducer';
import { reducer as users } from 'Store/users/reducer';
import { reducer as customers } from 'Store/usersAll/reducer';
import { reducer as orders } from 'Store/orders/reducer';
import { reducer as requests } from 'Store/requests/reducer';
import { reducer as calendar } from 'Store/calendar/reducer';
import { reducer as closedDays } from 'Store/closedDays/reducer';
import { reducer as offers } from 'Store/offers/reducer';
import { reducer as leads } from 'Store/leads/reducer';
import { reducer as databases } from 'Store/databases/reducer';
import { reducer as coordinatorUser } from 'Store/coordinatorUser/reducer';
import { reducer as coordinatorReports } from 'Store/coordinatorReports/reducer';
import { reducer as coordinatorSchemas } from 'Store/coordinatorSchemas/reducer';
import { reducer as coordinatorGroups } from 'Store/coordinatorGroups/reducer';
import { reducer as schemas } from 'Store/schemas/reducer';
import { reducer as tickets } from 'Store/tickets/reducer';
import { reducer as coordinatorAnalytics } from 'Store/coordinatorAnalytics/reducer';
import { reducer as employeeReports } from 'Store/employeeReports/reducer';

export const rootReducer = combineReducers({
  toastr,
  app,
  user,
  modal,
  menuNotifications,
  translations,
  settings,
  users,
  customers,
  orders,
  requests,
  calendar,
  closedDays,
  offers,
  leads,
  databases,
  coordinatorUser,
  coordinatorReports,
  coordinatorSchemas,
  coordinatorGroups,
  schemas,
  tickets,
  coordinatorAnalytics,
  employeeReports,
});
