import update from 'immutability-helper';
import * as actionTypes from 'Store/schemas/actionTypes';

type OffersState = {
  schema: any;
  schemas: any;
  isReady: boolean;
  isReadySchemas: boolean;
};

const initialState: OffersState = {
  schema: [],
  schemas: [],
  isReady: false,
  isReadySchemas: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCHEMAS: {
      const { schemas } = action.payload;

      return update(state, {
        schemas: {
          $set: schemas,
        },
      });
    }
    case actionTypes.SET_SCHEMAS_READY: {
      return update(state, {
        isReadySchemas: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_SCHEMAS: {
      return update(state, {
        schemas: {
          $set: initialState.schema,
        },
        isReadySchemas: {
          $set: initialState.isReadySchemas,
        },
      });
    }
    case actionTypes.SET_SCHEMA: {
      const { schema } = action.payload;

      return update(state, {
        schema: {
          $set: schema,
        },
      });
    }
    case actionTypes.SET_SCHEMA_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_SCHEMA: {
      return update(state, {
        schema: {
          $set: initialState.schema,
        },
        isReady: {
          $set: initialState.isReady,
        },
      });
    }
    default:
      return state;
  }
};
