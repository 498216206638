import { useTranslation } from 'react-i18next';
import { cnb } from 'cnbuilder';
import { useHistory } from 'react-router-dom';

import { HOME } from 'Constants/routes';

import Button from 'Components/@Base/Button';

import Logo from 'Components/@Shared/Logo';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleGoBack = () => {
    history.push(HOME);
  };

  return (
    <div className={styles.NotFound}>
      <Logo />

      <div className={cnb(styles.NotFound__content, 'mt-3 mx-auto p-3 mt-sm-6 p-sm-6')}>
        <h1 className="text-center">{t('Błąd 404')}</h1>
        <p className="text-center">{t('Zgubiłeś się?')}</p>
        <p className="text-center">{t('Niestety nie znaleźliśmy tego, czego szukałeś.')}</p>
        <Button className="mt-3" type="button" label={t('Wróć')} onClick={handleGoBack} />
      </div>
    </div>
  );
};

export default NotFound;
