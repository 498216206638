import PageContainer from 'Components/@Shared/PageContainer';

import DotLoader from 'Components/@Base/DotLoader';

import styles from './PageLoader.module.scss';

const PageLoader = () => (
  <PageContainer className={styles.Container}>
    <DotLoader className={styles.Loader} />
  </PageContainer>
);

export default PageLoader;
