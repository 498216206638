import { cnb } from 'cnbuilder';

import { useAppSelector } from 'Hooks/redux-hooks';

import Menu from 'Layouts/WithSidebarLayout/components/Sidebar/components/Menu';

import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const { sidebarOpen } = useAppSelector((state) => state.app);

  return (
    <div className={cnb(styles.Sidebar, sidebarOpen && styles.Sidebar_isOpen)}>
      <div className={styles.Sidebar__menuWrapper}>
        <Menu />
      </div>
    </div>
  );
};

export default Sidebar;
