import React from 'react';
import Tippy from '@tippyjs/react/headless';
import { useMediaQuery } from 'react-responsive';
import { cnb } from 'cnbuilder';

import styles from './TooltipInput.module.scss';

type TooltipInputProps = {
  className?: string;
  innerRef?: any;
  name?: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  tooltipComponent: JSX.Element;
};

const TooltipInput = ({
  className,
  innerRef,
  name,
  type,
  value,
  onChange,
  placeholder,
  tooltipComponent,
}: TooltipInputProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  return (
    <div className={cnb(styles.InputWrapper, className)}>
      <Tippy
        render={(attrs) =>
          React.cloneElement(tooltipComponent, { attrs, 'data-placement': isDesktop ? 'right' : 'top' })
        }
        placement={isDesktop ? 'right' : 'top'}
        trigger="focus"
        hideOnClick={false}
      >
        <input
          ref={innerRef}
          className={styles.Input}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
        />
      </Tippy>
    </div>
  );
};

TooltipInput.defaultProps = {
  className: '',
  innerRef: undefined,
  name: '',
  type: 'text',
  placeholder: '',
};

export default TooltipInput;
