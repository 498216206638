/* eslint-disable react/no-unused-prop-types */
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { v4 as uuid } from 'uuid';
import { MdChevronRight } from 'react-icons/all';

import { ROUTE_CONFIG } from 'Routes/config';

import styles from './Breadcrumbs.module.scss';

const insertSpacers = (breadcrumbs: Array<any>) => {
  for (let i = 1; i < breadcrumbs.length; i += 2) {
    breadcrumbs.splice(i, 0, {
      id: uuid(),
      isSpacer: true,
      breadcrumb: <MdChevronRight />,
    });
  }

  return breadcrumbs;
};

type BreadcrumbsProps = {
  id: number;
  key: string;
  match: { url: string };
  breadcrumb: any;
  isSpacer: boolean;
};

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const breadcrumbs = insertSpacers(useReactRouterBreadcrumbs(ROUTE_CONFIG));
  return (
    <div className="container pt-5">
      <div className={styles.Breadcrumbs}>
        {breadcrumbs.map(({ id, key, match, breadcrumb, isSpacer }: BreadcrumbsProps, index: number) => {
          // is last element
          if (
            index === breadcrumbs.length - 1 ||
            key === '/backoffice' ||
            key === '/coordinator' ||
            key === '/coordinator/reports/report' ||
            key === '/coordinator/schemas/schema' ||
            key === '/customers/edit' ||
            key === '/coordinator/groups/edit' ||
            key === '/orders/edit' ||
            key === '/requests/edit' ||
            key === '/backoffice/users/edit' ||
            key === '/backoffice/settings/edit' ||
            key === '/backoffice/exchange'
          ) {
            return (
              <span key={match.url} className={styles.Breadcrumbs__item}>
                {breadcrumb?.props?.children === 'Coordinator' ? (
                  'Koordinator'
                ) : breadcrumb?.props?.children ? (
                  <span>{t(breadcrumb.props.children)}</span>
                ) : (
                  breadcrumb
                )}
              </span>
            );
          }

          // spacer
          if (isSpacer) {
            return (
              <span key={id} className={styles.Breadcrumbs__item}>
                {breadcrumb?.props?.children ? <span>{t(breadcrumb.props.children)}</span> : breadcrumb}
              </span>
            );
          }

          return (
            <span key={match.url} className={styles.Breadcrumbs__item}>
              <NavLink to={match.url}>
                {breadcrumb?.props?.children ? <span>{t(breadcrumb.props.children)}</span> : breadcrumb}
              </NavLink>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
