import React from 'react';
import { cnb } from 'cnbuilder';
import { Spinner } from 'react-bootstrap';

import styles from './ButtonWithIcon.module.scss';

type ButtonProps = {
  className?: string;
  label: string;
  icon: any;
  type: 'button' | 'submit';
  buttonType?: 'primary' | 'secondary';
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonWithIcon = ({
  className,
  label,
  type,
  buttonType,
  isSubmitting,
  isDisabled,
  icon: Icon,
  onClick,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={cnb(
        className,
        styles.Button,
        buttonType && styles[`Button__${buttonType}`],
        isDisabled && styles[`Button__isDisabled__${buttonType}`],
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      {...rest}
    >
      {isSubmitting ? (
        <Spinner className={styles.Button__loader} animation="border" />
      ) : (
        <div className="d-flex flex-row align-items-center">
          <Icon className="mr-2" />
          <span className={styles.Label}>{label}</span>
        </div>
      )}
    </button>
  );
};

ButtonWithIcon.defaultProps = {
  className: '',
  buttonType: 'primary',
  isSubmitting: false,
  isDisabled: false,
  onClick: undefined,
};

export default ButtonWithIcon;
