import { cnb } from 'cnbuilder';

type PageContainerProps = {
  className?: string;
  children: JSX.Element;
};

const PageContainer = ({ className, children }: PageContainerProps) => (
  <div className={cnb('container pt-3 pb-5', className)}>{children}</div>
);

PageContainer.defaultProps = {
  className: '',
};

export default PageContainer;
