import React from 'react';
import { cnb } from 'cnbuilder';
import { Controller } from 'react-hook-form';

import { isNotEmptyObject } from 'Utils/common';

import Label from 'Components/@Base/Label';
import InputFeedback from 'Components/@Base/InputFeedback';
import Input from 'Components/@Base/Input';
import Checkbox from 'Components/@Base/Checkbox';
import TooltipInput from 'Components/@Base/TooltipInput';
import Textarea from 'Components/@Base/Textarea';
import Select from 'Components/@Base/Select';
import MUIDateTimePicker from 'Components/@Base/DateTimePicker';
import AsyncSelect from 'Components/@Base/AsyncSelect';

import styles from './InputWrapper.module.scss';

type InputWrapperProps = {
  className?: string;
  componentType: 'input' | 'checkbox' | 'tooltipInput' | 'textarea' | 'select' | 'datetimepicker' | 'asyncSelect';
  labelProps?: { className?: string; label?: any };
  inputProps?: {
    className?: string;
    innerRef?: any;
    name?: string;
    placeholder?: string;
    onChange?: any;
    value?: string | number;
    type?: string;
    autoComplete?: string;
    setValue?: any;
    disabled?: boolean;
  };
  checkboxProps?: {
    className?: string;
    innerRef?: any;
    name?: string;
    label?: string | JSX.Element;
    handleClick?: any;
    isChecked?: any;
  };
  tooltipInputProps?: {
    className?: string;
    innerRef?: any;
    name?: string;
    type?: string;
    placeholder?: string;
    autoComplete?: string;
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    tooltipComponent: JSX.Element;
  };
  selectProps?: {
    name?: string;
    control?: any;
    className?: string;
    defaultValue?: any;
    value?: any;
    findValue?: any;
    placeholder?: string;
    options?: Array<{
      label?: any;
      id?: string | number;
      value?: any;
      name?: string;
      image?: string;
    }>;
    onChange?: any;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    getOptionLabel?: any;
    loadOptions?: any;
    getOptionValue?: any;
    loadingMessage?: any;
    noOptionsMessage?: any;
    cacheOptions?: boolean;
    isDisabled?: boolean;
  };
  dateTimePickerProps?: {
    control?: any;
    className?: string;
    name?: string;
    value?: Date;
    onChange: any;
    disableClock?: boolean;
    disabled?: boolean;
    defaultValue?: string | number;
    calendarIcon?: string | null;
    inputFormat: string;
    views?: Array<string | number>;
    minDate?: string;
    minutesStep?: number;
  };
  feedback?: {
    message?: string;
    isError?: boolean;
  };
};

const InputWrapper = ({
  className,
  componentType,
  labelProps,
  inputProps,
  checkboxProps,
  tooltipInputProps,
  feedback,
  selectProps,
  dateTimePickerProps,
}: InputWrapperProps) => {
  const renderInput = () => {
    let template;

    switch (componentType) {
      case 'input':
        template = (
          <>
            {labelProps && isNotEmptyObject(labelProps) ? <Label htmlFor={inputProps?.name} {...labelProps} /> : null}
            <Input {...inputProps} />
          </>
        );
        break;
      case 'tooltipInput':
        template = (
          <>
            {labelProps && isNotEmptyObject(labelProps) ? <Label htmlFor={inputProps?.name} {...labelProps} /> : null}
            {tooltipInputProps && <TooltipInput {...tooltipInputProps} />}
          </>
        );
        break;
      case 'checkbox':
        template = <>{checkboxProps && <Checkbox {...checkboxProps} />}</>;
        break;
      case 'textarea':
        template = (
          <>
            {labelProps && isNotEmptyObject(labelProps) ? <Label htmlFor={inputProps?.name} {...labelProps} /> : null}
            <Textarea {...inputProps} />
          </>
        );
        break;
      case 'select':
        template = (
          <>
            {isNotEmptyObject(labelProps) ? <Label htmlFor={selectProps.name} {...labelProps} /> : null}
            <Controller
              control={selectProps.control}
              name={selectProps.name}
              defaultValue={selectProps.defaultValue}
              shouldUnregister
              render={({ ref, onChange, ...rest }) => (
                <Select {...selectProps} {...rest} onChange={(selected) => onChange(selectProps.onChange(selected))} />
              )}
            />
          </>
        );
        break;
      case 'datetimepicker':
        template = (
          <>
            {isNotEmptyObject(labelProps) ? <Label htmlFor={undefined} {...labelProps} /> : null}
            <Controller
              control={dateTimePickerProps.control}
              name={dateTimePickerProps.name}
              defaultValue={dateTimePickerProps.defaultValue}
              render={({ ref, onChange, ...rest }) => (
                <MUIDateTimePicker
                  {...dateTimePickerProps}
                  {...rest}
                  onChange={(selected) => onChange(dateTimePickerProps.onChange(selected))}
                />
              )}
            />
          </>
        );
        break;
      case 'asyncSelect':
        template = (
          <>
            {isNotEmptyObject(labelProps) ? <Label htmlFor={selectProps.name} {...labelProps} /> : null}
            <Controller
              control={selectProps.control}
              name={selectProps.name}
              render={({ ref, onChange, ...rest }) => (
                <AsyncSelect
                  {...selectProps}
                  {...rest}
                  onChange={(selected) => onChange(selectProps.onChange(selected))}
                />
              )}
              {...selectProps}
            />
          </>
        );
        break;
      default:
        template = (
          <>
            {labelProps && isNotEmptyObject(labelProps) ? <Label htmlFor={inputProps?.name} {...labelProps} /> : null}
            <Input {...inputProps} />
          </>
        );
    }

    return template;
  };

  return (
    <div className={cnb(styles.InputContainer, className)}>
      {renderInput()}
      {feedback?.message && <InputFeedback feedback={feedback.message} isError={feedback.isError} />}
    </div>
  );
};

InputWrapper.defaultProps = {
  className: '',
  labelProps: {},
  inputProps: {},
  checkboxProps: {},
  tooltipInputProps: {},
  selectProps: {},
  dateTimePickerProps: {},
  feedback: {},
};

export default InputWrapper;
