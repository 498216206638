import { NavLink } from 'react-router-dom';
import { cnb } from 'cnbuilder';
import { IconType } from 'react-icons/lib';

import Icon from 'Components/@Base/Icon';

import styles from './MenuItem.module.scss';

type MenuItemContentProps = {
  label: string;
  leftIcon?: IconType | string;
  rightIcon?: IconType;
  notification?: number;
  isImage?: boolean;
  image?: string;
};

type MenuItemProps = {
  className?: string;
  label: string;
  isImage?: boolean;
  leftIcon?: IconType | string;
  rightIcon?: IconType;
  notification?: number;
  image?: string;
  url?: string;
  url_newPage?: string;
  parentMenu?: string;
  onGoToMenu?: any;
  isDisabled?: boolean;
  exact?: boolean;
  onClick?: any;
  border?: boolean;
};

const NotificationCounter = ({ count }: { count: number | string }) => (
  <span className={styles.NotificationCounter}>{count}</span>
);

const MenuItemContent = ({
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  label,
  notification,
  isImage,
  image,
}: MenuItemContentProps) => (
  <>
    {isImage && (
      <Icon className={cnb(styles.MenuItem__image, 'mr-3')}>
        <img
          src={image}
          alt="icon"
          style={{
            width: '20px',
            height: '20px',
            filter: 'invert(96%) sepia(7%) saturate(3233%) hue-rotate(186deg) brightness(91%) contrast(85%)',
          }}
        />
      </Icon>
    )}
    {LeftIcon && (
      <Icon className={cnb(styles.MenuItem__icon, 'mr-3')}>
        <LeftIcon style={{ width: '20px', height: '20px' }} />
      </Icon>
    )}

    <span className={styles.MenuItem__label}>
      {label} {!!notification && <NotificationCounter count={notification} />}
    </span>

    {RightIcon && (
      <Icon className={cnb(styles.MenuItem__icon, 'ml-auto')}>
        <RightIcon style={{ width: '20px', height: '20px' }} />
      </Icon>
    )}
  </>
);

const MenuItem = ({
  className,
  label,
  leftIcon,
  rightIcon,
  image,
  isImage,
  notification,
  url,
  url_newPage,
  parentMenu,
  onGoToMenu,
  isDisabled,
  exact,
  onClick,
  border,
  ...rest
}: MenuItemProps) => {
  if (url) {
    return (
      <NavLink
        className={cnb(
          styles.MenuItem,
          isDisabled && styles.MenuItem_isDisabled,
          border && styles.MenuItem__border,
          className,
        )}
        activeClassName={styles.MenuItem_isActive}
        to={url}
        exact={exact}
        {...rest}
      >
        <MenuItemContent
          label={label}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          notification={notification}
          image={image}
          isImage={isImage}
        />
      </NavLink>
    );
  }
  if (url_newPage) {
    return (
      <a
        className={cnb(
          styles.MenuItem,
          isDisabled && styles.MenuItem_isDisabled,
          border && styles.MenuItem__border,
          className,
        )}
        target="_blank"
        rel="noreferrer"
        // activeClassName={styles.MenuItem_isActive}
        href={url_newPage}
        // to={url_newPage}
        // exact={exact}
        {...rest}
      >
        <MenuItemContent
          label={label}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          notification={notification}
          image={image}
          isImage={isImage}
        />
      </a>
    );
  }

  return (
    <button
      type="button"
      className={cnb(styles.MenuItem, className, border && styles.MenuItem__border, 'button')}
      onClick={() => onGoToMenu(parentMenu)}
    >
      <MenuItemContent label={label} leftIcon={leftIcon} rightIcon={rightIcon} notification={notification} />
    </button>
  );
};

MenuItemContent.defaultProps = {
  rightIcon: undefined,
  leftIcon: undefined,
  notification: undefined,
  isImage: false,
  image: undefined,
};

MenuItem.defaultProps = {
  className: '',
  rightIcon: undefined,
  leftIcon: undefined,
  isImage: false,
  image: undefined,
  notification: undefined,
  url: '',
  url_newPage: '',
  parentMenu: '',
  onGoToMenu: undefined,
  isDisabled: false,
  exact: false,
  onClick: undefined,
  border: false,
};

export default MenuItem;
