import { components } from 'react-select';

import styles from './SelectMultiValueContainer.module.scss';

type SelectMultiValueContainerProps = {
  innerProps: {};
  children: any;
  data: any;
  selectProps: any;
};

const SelectMultiValueContainer = ({ innerProps, ...rest }: SelectMultiValueContainerProps) => {
  return (
    <components.MultiValueContainer {...rest} innerProps={{ ...innerProps, className: styles.MultiValueContainer }} />
  );
};

export default SelectMultiValueContainer;
