export const SET_TICKET = '@@tickets/SET_TICKET';
export const SET_TICKET_READY = '@@tickets/SET_TICKET_READY';
export const CLEAR_TICKET = '@@tickets/CLEAR_TICKET';
export const SET_TICKETS = '@@tickets/SET_TICKETS';
export const SET_TICKETS_ADMIN = '@@tickets/SET_TICKETS_ADMIN';
export const SET_TICKETS_READY = '@@tickets/SET_TICKETS_READY';
export const SET_CURRENT_PAGE = '@@tickets/SET_CURRENT_PAGE';
export const SET_FILTERS = '@@tickets/SET_FILTERS';
export const SET_FILTERS_PARAMS = '@@tickets/SET_FILTERS_PARAMS';
export const RESET_FILTERS = '@@tickets/RESET_FILTERS';
export const TOGGLE_FILTERS_SIDEBAR = '@@tickets/TOGGLE_FILTERS_SIDEBAR';
export const CLEAR_TICKETS = '@@tickets/CLEAR_TICKETS';
