import { cnb } from 'cnbuilder';

import styles from './InputFeedback.module.scss';

type InputFeedbackProps = {
  className?: string;
  feedback: string;
  isError?: boolean;
};

const InputFeedback = ({ className, feedback, isError }: InputFeedbackProps) => (
  <span
    className={cnb(
      styles.InputFeedback,
      isError && styles.InputFeedback_error,
      !feedback && styles.InputFeedback_hidden,
      className,
    )}
  >
    {feedback}
  </span>
);

InputFeedback.defaultProps = {
  className: '',
  isError: false,
};

export default InputFeedback;
