/* eslint react/require-default-props: 0 */

import { cnb } from 'cnbuilder';

import Button from 'Components/@Base/Button';
import ButtonWithIcon from 'Components/@Base/ButtonWithIcon';

import styles from './ButtonWrapper.module.scss';

type ButtonWrapperProps = {
  className?: string;
  component?: 'button' | 'buttonWithIcon';
  label: string;
  alignment: 'left' | 'center' | 'right' | 'space-between' | 'space-around' | 'space-evenly';
  type: 'button' | 'submit';
  buttonType?: 'primary' | 'secondary';
  isSubmitting?: boolean;
  isDisabled?: boolean;
  icon?: any;
  onClick?: any;
};

const ButtonWrapper = ({
  className,
  component,
  alignment,
  label,
  type,
  buttonType,
  isSubmitting,
  isDisabled,
  onClick,
  icon,
}: ButtonWrapperProps) => {
  const renderButton = () => {
    const Component =
      component === 'button' ? (
        <Button
          label={label}
          type={type}
          buttonType={buttonType}
          isDisabled={isDisabled}
          isSubmitting={isSubmitting}
          onClick={onClick}
        />
      ) : (
        <ButtonWithIcon
          label={label}
          type={type}
          buttonType={buttonType}
          isDisabled={isDisabled}
          isSubmitting={isSubmitting}
          onClick={onClick}
          icon={icon}
        />
      );

    return Component;
  };

  return (
    <div className={cnb(styles.ButtonWrapper, alignment ? styles[`ButtonWrapper--${alignment}`] : '', className)}>
      {renderButton()}
    </div>
  );
};

ButtonWrapper.defaultProps = {
  className: '',
  component: 'button',
};

export default ButtonWrapper;
