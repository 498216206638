import { lazy } from 'react';
import { v4 as uuid } from 'uuid';

import * as ROUTES from 'Constants/routes';
import * as ROUTE_TYPES from 'Constants/routeTypes';
import AddGroup from 'Containers/AddGroup';
import EditGroup from 'Containers/EditGroup';
import Bot from 'Containers/Bot';
import ReportsEmployee from 'Containers/ReportsEmployee';
import Analytics from 'Containers/Analytics';
import ConversationScripts from 'Containers/ConversationScripts';

const Login = lazy(() => import('Containers/Login'));
const RemindPassword = lazy(() => import('Containers/RemindPassword'));
const ResetPassword = lazy(() => import('Containers/ResetPassword'));
const UserChangePassword = lazy(() => import('Containers/UserChangePassword'));
const Register = lazy(() => import('Containers/Register'));
const Verification = lazy(() => import('Containers/Verification'));

const Dashboard = lazy(() => import('Containers/Dashboard'));
const Profile = lazy(() => import('Containers/Profile'));
const Users = lazy(() => import('Containers/Users'));
const Calendar = lazy(() => import('Containers/Calendar'));
const Schemas = lazy(() => import('Containers/Schemas'));
const Schema = lazy(() => import('Containers/SchemaTable'));
const Reports = lazy(() => import('Containers/Reports'));
const Report = lazy(() => import('Containers/ReportTable'));
const Groups = lazy(() => import('Containers/Groups'));

const Tickets = lazy(() => import('Containers/Tickets'));
const NewTicket = lazy(() => import('Containers/TicketNew'));
const Ticket = lazy(() => import('Containers/Ticket'));

const AdminUserEdit = lazy(() => import('Containers/Backoffice/EditUser'));
const AdminAddUser = lazy(() => import('Containers/Backoffice/AddUser'));

export const ROUTE_CONFIG = [
  {
    id: uuid(),
    path: ROUTES.HOME,
    component: Dashboard,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    exact: true,
    breadcrumb: 'Tablica',
  },
  {
    id: uuid(),
    path: ROUTES.LOGIN,
    component: Login,
    type: ROUTE_TYPES.TYPE_PUBLIC,
  },
  {
    id: uuid(),
    path: ROUTES.REGISTER,
    component: Register,
    type: ROUTE_TYPES.TYPE_PUBLIC,
  },
  {
    id: uuid(),
    path: ROUTES.VERIFICATION,
    component: Verification,
    type: ROUTE_TYPES.TYPE_PUBLIC,
  },
  {
    id: uuid(),
    path: ROUTES.REMIND_PASSWORD,
    component: RemindPassword,
    type: ROUTE_TYPES.TYPE_PUBLIC,
  },
  {
    id: uuid(),
    path: ROUTES.RESET_PASSWORD,
    component: ResetPassword,
    type: ROUTE_TYPES.TYPE_PUBLIC,
  },

  {
    id: uuid(),
    path: ROUTES.USER_CHANGE_PASSWORD,
    component: UserChangePassword,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Zmień hasło',
  },
  {
    id: uuid(),
    path: ROUTES.PROFILE,
    component: Profile,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Profil',
  },
  {
    id: uuid(),
    path: ROUTES.USERS,
    component: Users,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Użytkownicy',
  },
  {
    id: uuid(),
    path: ROUTES.TICKETS_NEW,
    component: NewTicket,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Nowe zgłoszenie',
  },
  {
    id: uuid(),
    path: `${ROUTES.TICKETS}/:ticketRef`,
    component: Ticket,
    type: ROUTE_TYPES.TYPE_PRIVATE,
  },
  {
    id: uuid(),
    path: ROUTES.TICKETS,
    component: Tickets,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Zgłoszenies',
  },
  //   GROUPS
  {
    id: uuid(),
    path: ROUTES.GROUP_EDIT,
    component: EditGroup,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Edytuj grupę',
    coordinator: true,
  },
  {
    id: uuid(),
    path: ROUTES.GROUP_ADD,
    component: AddGroup,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Dodaj grupę',
    coordinator: true,
  },
  {
    id: uuid(),
    path: ROUTES.GROUPS,
    component: Groups,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Grupy',
    coordinator: true,
  },
  {
    id: uuid(),
    path: ROUTES.REPORTS_EMPLOYEE,
    component: ReportsEmployee,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Raporty',
  },
  {
    id: uuid(),
    path: ROUTES.BOT,
    component: Bot,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Bot',
  },
  {
    id: uuid(),
    path: ROUTES.REPORT,
    component: Report,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Raport',
  },
  {
    id: uuid(),
    path: ROUTES.REPORTS,
    component: Reports,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Raporty',
  },
  {
    id: uuid(),
    path: ROUTES.SCHEMA,
    component: Schema,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Schemat',
  },
  {
    id: uuid(),
    path: ROUTES.SCHEMAS,
    component: Schemas,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Schematy',
  },
  // CALENDAR
  {
    id: uuid(),
    path: ROUTES.CALENDAR,
    component: Calendar,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Calendar',
  },
  {
    id: uuid(),
    path: ROUTES.ANALITICS,
    component: Analytics,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Analityka',
  },
  {
    id: uuid(),
    path: ROUTES.SCRIPTS,
    component: ConversationScripts,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Skrypty rozmów',
  },
  // USER
  {
    id: uuid(),
    path: ROUTES.ADMIN_USERS_EDIT,
    component: AdminUserEdit,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    coordinator: true,
  },
  {
    id: uuid(),
    path: ROUTES.ADMIN_USER_ADD,
    component: AdminAddUser,
    type: ROUTE_TYPES.TYPE_PRIVATE,
    breadcrumb: 'Dodaj użytkownika',
    coordinator: true,
  },
];
