import API from 'Lib/axios';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from 'Store/store';

import { USER_ENDPOINTS } from 'Constants/endpoints';

import * as actionTypes from 'Store/users/actionTypes';

export const toggleUsersFiltersSidebarAction = (filtersOpen: boolean) => ({
  type: actionTypes.TOGGLE_FILTERS_SIDEBAR,
  payload: {
    filtersOpen,
  },
});

export const toggleUsersFiltersSidebar = (filtersOpen?: boolean) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    if (typeof filtersOpen !== 'undefined') {
      dispatch(toggleUsersFiltersSidebarAction(filtersOpen));
    } else {
      const { filtersOpen: open } = getState().users;

      dispatch(toggleUsersFiltersSidebarAction(!open));
    }
  };
};

/** USERS ############################################################################################################ */
export const setUsersAction = (users: Array<{}>) => ({
  type: actionTypes.SET_USERS,
  payload: {
    users,
  },
});

export const setUsersCurrentPageAction = (currentPage: number) => ({
  type: actionTypes.SET_CURRENT_PAGE,
  payload: {
    currentPage,
  },
});

export const setUsersFiltersAction = (filters: {}) => ({
  type: actionTypes.SET_FILTERS,
  payload: {
    filters,
  },
});

export const setUsersFiltersParamsAction = (filtersParams: {}) => ({
  type: actionTypes.SET_FILTERS_PARAMS,
  payload: {
    filtersParams,
  },
});

export const resetUsersFiltersAction = () => ({
  type: actionTypes.RESET_FILTERS,
});

export const setUsersReadyAction = () => ({
  type: actionTypes.SET_USERS_READY,
});

export const clearUsersAction = () => ({
  type: actionTypes.CLEAR_USERS,
});

export const fetchUsersWithFilters = () => {
  return API.get(`${USER_ENDPOINTS.USERS}`);
};

export const getUsers = (init = false) => {
  return (dispatch: Dispatch) => {
    fetchUsersWithFilters().then((res) => {
      const { users } = res.data;

      dispatch(setUsersAction(users));

      if (init) {
        dispatch(setUsersReadyAction());
      }
    });
  };
};
