import { cnb } from 'cnbuilder';

import styles from './Input.module.scss';

type InputProps = {
  className?: string;
  innerRef?: any;
  name?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
};

const Input = ({ className, innerRef, name, placeholder, type, autoComplete, ...rest }: InputProps) => {
  return (
    <div className={cnb(styles.InputWrapper, className)}>
      <input
        ref={innerRef}
        className={styles.Input}
        placeholder={placeholder}
        name={name}
        type={type}
        autoComplete={autoComplete}
        {...rest}
      />
    </div>
  );
};

Input.defaultProps = {
  className: '',
  innerRef: undefined,
  name: '',
  placeholder: '',
  type: '',
  autoComplete: '',
};

export default Input;
