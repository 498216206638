import { cnb } from 'cnbuilder';

import DotLoader from 'Components/@Base/DotLoader';

import styles from './LoaderWithMessage.module.scss';

type LoaderWithMessageProps = {
  className?: string;
  messageProps: {
    className: string;
    message: string;
  };
  loaderProps?: {
    className?: string;
  };
};

const LoaderWithMessage = ({ className, messageProps, loaderProps }: LoaderWithMessageProps) => (
  <div className={cnb(styles.Loader, className)}>
    {messageProps.message ? <h2 className={messageProps.className}>{messageProps.message}</h2> : null}

    <DotLoader {...loaderProps} />
  </div>
);

LoaderWithMessage.defaultProps = {
  className: '',
  loaderProps: {},
};

export default LoaderWithMessage;
