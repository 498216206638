import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';

// eslint-disable-next-line import/no-extraneous-dependencies
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';
import NoResults from 'Components/@Shared/NoResults';
import Loader from 'Components/@Shared/Loader';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { clearReportsAction, getReports } from 'Store/employeeReports/actions';
import { ETAP_STATUS } from 'Constants/sourcing';
import { Box, Tab, Tabs, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReportsEmployee = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { reports, isReady } = useAppSelector((state) => state.employeeReports);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch<any>(getReports(true));

    return () => {
      dispatch(clearReportsAction());
    };
  }, [dispatch]);

  const stageData = {};

  reports.forEach((report) => {
    if (!stageData[ETAP_STATUS[report.stage]]) {
      stageData[ETAP_STATUS[report.stage]] = [];
    }
    stageData[ETAP_STATUS[report.stage]].push(report);
  });

  const xAxisData: any = {
    id: 'barCategories',
    data: Object.keys(stageData).map((key) => (key === 'undefined' ? 'Nieznany' : key)),
    scaleType: 'band',
  };

  const seriesData = Object.values(stageData).map((reps: any) => reps.length);
  const seriesLineDataTime = reports.map((reps: any) => (reps.time ? reps.time : 0));
  const seriesLineDataSteps = reports.map((reps: any) => (reps.steps_completed ? reps.steps_completed : 0));
  const seriesLineData = reports.map((_, index) => index + 1);

  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>{t('Moje raporty')}</div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Przeprowadzone rozmowy" {...a11yProps(0)} />
                    <Tab label="Czas rozmowy" {...a11yProps(1)} />
                    <Tab label="Ukończone etapy" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    {isReady ? (
                      !!seriesData.length && (
                        <>
                          <h5>{`Liczba przeprowadzonych rozmów: ${reports.length}`}</h5>
                          <div
                            style={{
                              width: '100%',
                              maxWidth: '800px',
                              height: '300px',
                              margin: '0 auto',
                            }}
                          >
                            <BarChart
                              sx={{ width: '100%', height: '100%' }}
                              colors={['#024a7c']}
                              xAxis={[xAxisData]}
                              series={[
                                {
                                  data: seriesData,
                                },
                              ]}
                            />
                          </div>
                        </>
                      )
                    ) : (
                      <Loader minHeight="300px" />
                    )}

                    {isReady && seriesData.length === 0 && <NoResults />}
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    {isReady ? (
                      !!seriesLineDataTime.length && (
                        <>
                          <h5>{`Liczba przeprowadzonych rozmów: ${reports.length}`}</h5>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              maxWidth: '800px',
                              height: '300px',
                              margin: '0 auto',
                            }}
                          >
                            <LineChart
                              sx={{ width: '100%', height: '100%' }}
                              xAxis={[{ data: seriesLineData }]}
                              series={[
                                {
                                  data: seriesLineDataTime,
                                },
                              ]}
                            />
                          </div>
                        </>
                      )
                    ) : (
                      <Loader minHeight="300px" />
                    )}

                    {isReady && seriesLineDataTime.length === 0 && <NoResults />}
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    {isReady ? (
                      !!seriesLineDataSteps.length && (
                        <>
                          <h5>{`Liczba przeprowadzonych rozmów: ${reports.length}`}</h5>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              maxWidth: '800px',
                              height: '300px',
                              margin: '0 auto',
                            }}
                          >
                            <LineChart
                              sx={{ width: '100%', height: '100%' }}
                              xAxis={[{ data: seriesLineData }]}
                              series={[
                                {
                                  data: seriesLineDataSteps,
                                },
                              ]}
                            />
                          </div>
                        </>
                      )
                    ) : (
                      <Loader minHeight="300px" />
                    )}

                    {isReady && seriesLineDataSteps.length === 0 && <NoResults />}
                  </div>
                </CustomTabPanel>
              </Box>
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default ReportsEmployee;
