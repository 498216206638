export const SET_IS_LOADING = '@@app/IS_LOADING';
export const TOGGLE_SIDEBAR = '@@app/TOGGLE_SIDEBAR';
export const SET_ITEMS_PER_PAGE = '@@app/SET_ITEMS_PER_PAGE';
export const SET_LANGUAGES = '@@app/SET_LANGUAGES';
export const SET_CUSTOMER_STATUSES = '@@app/SET_CUSTOMER_STATUSES';
export const SET_REQUEST_STATUSES = '@@app/SET_REQUEST_STATUSES';
export const SET_ORDER_STATUSES = '@@app/SET_ORDER_STATUSES';
export const SET_ACCOUNTS = '@@app/SET_ACCOUNTS';
export const SET_SUMMARY = '@@app/SET_SUMMARY';
export const SET_CUSTOMER_TYPES = '@@app/SET_CUSTOMER_TYPES';
export const SET_CUSTOMER_SOURCES = '@@app/SET_CUSTOMER_SOURCES';
export const SET_HALLS = '@@app/SET_HALLS';
export const SET_CUSTOMERS = '@@app/SET_CUSTOMERS';
export const SET_EVENTS = '@@app/SET_EVENTS';
export const SET_AREA_CODES = '@@app/SET_AREA_CODES';
export const SET_PAY_STATUSES = '@@app/SET_PAY_STATUSES';
export const SET_SUB_STATUS_NOT_INTERESTED = '@@app/SET_SUB_STATUS_NOT_INTERESTED';
export const SET_DATABASE_STATUSES = '@@app/SET_DATABASE_STATUSES';
export const SET_LEAD_STATUSES = '@@app/SET_LEAD_STATUSES';
export const SET_DATABASES = '@@app/SET_DATABASES';
export const SET_PROVINCES = '@@app/SET_PROVINCES';
export const SET_LEGAL_FORMS = '@@app/SET_LEGAL_FORMS';
export const SET_PKD_CODES = '@@app/SET_PKD_CODES';
