import { useMediaQuery } from 'react-responsive';
import { cnb } from 'cnbuilder';

import Logo from 'Layouts/WithSidebarLayout/components/Header/components/Logo';
import AccountDropdown from 'Layouts/WithSidebarLayout/components/Header/components/AccountDropdown';
import Hamburger from 'Layouts/WithSidebarLayout/components/Header/components/Hamburger';
import MobileLanguageSelector from 'Layouts/WithSidebarLayout/components/Header/components/MobileLanguageSelector';

import styles from './Header.module.scss';

const Header = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  return (
    <nav className={styles.Header}>
      <div className={styles.Header__top}>
        <MobileLanguageSelector />
      </div>

      <div className={styles.Header__main}>
        <div className={styles.Header__block_left}>
          <Logo />
        </div>

        <div className={cnb(styles.Header__block_right)}>
          <AccountDropdown />

          {!isDesktop && (
            <div className={cnb(styles.Header__languageSelectorWrapper, 'px-3')}>
              <Hamburger isDesktop={isDesktop} />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
