import { cnb } from 'cnbuilder';

import styles from './Block.module.scss';

type BlockProps = {
  className?: string;
  children: JSX.Element;
  element: any;
};

const Block = ({ className, children, element: Element, ...rest }: BlockProps) => (
  <Element className={cnb(styles.Block, className)} {...rest}>
    {children}
  </Element>
);

Block.defaultProps = {
  className: '',
};

export default Block;
