import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { MdKeyboardArrowDown } from 'react-icons/all';

import { useAppSelector } from 'Hooks/redux-hooks';

import { getCountryIso } from 'Utils/common';

import Icon from 'Components/@Base/Icon';
import Dropdown from 'Components/@Base/Dropdown';

import { cnb } from 'cnbuilder';
import stylesDropdown from 'Components/@Base/Dropdown/Dropdown.module.scss';
import { useHistory } from 'react-router-dom';
import styles from './LanguageSelector.module.scss';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { languages } = useAppSelector((state) => state.app);

  const header = (
    <>
      <div className={styles.LanguageSelector__icon}>
        <ReactSVG src={`/flags/${getCountryIso(i18n.language)}.svg`} />
      </div>
      <Icon className={cnb(stylesDropdown.arrow, 'ml-lg-3')}>
        <MdKeyboardArrowDown style={{ height: '20px', width: '20px' }} />
      </Icon>
    </>
  );
  const history = useHistory();

  return languages.length ? (
    <Dropdown className={styles.LanguageSelector} header={header}>
      <>
        {languages.map(({ id, locale }) => {
          if (locale === i18n.language) {
            return null;
          }

          return (
            <button
              key={id}
              type="button"
              onClick={() => {
                i18n.changeLanguage(locale);
                history.go(0);
              }}
            >
              <div className={styles.LanguageSelector__icon}>
                <ReactSVG src={`/flags/${getCountryIso(locale)}.svg`} />
              </div>
            </button>
          );
        })}
      </>
    </Dropdown>
  ) : null;
};

export default LanguageSelector;
