import { Suspense } from 'react';
import Routes from 'Routes';

import AccountProvider from 'Providers/AccountProvider';
import ModalProvider from 'Providers/ModalProvider';

import Toastr from 'Components/@Shared/Toastr';
import PageLoader from 'Components/@Shared/PageLoader';

const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <AccountProvider>
        <>
          <Routes />
          <Toastr />
        </>
      </AccountProvider>
      <ModalProvider name="modal" />
    </Suspense>
  );
};

export default App;
