import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'react-cookies';
import { toastr } from 'react-redux-toastr';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';

import { setLoggedInAction, setDetailsAction } from 'Store/user/actions';
import { setIsLoadingAction } from 'Store/app/actions';

import API from 'Lib/axios';
import { ACCOUNT_ENDPOINTS } from 'Constants/endpoints';
import { COOKIE_SID } from 'Utils/cookies';
import { LOGIN } from 'Constants/routes';

import AppLoader from 'Components/AppLoader';
import { useTranslation } from 'react-i18next';

type AccountProviderProps = {
  children: JSX.Element;
};

const AccountProvider = ({ children }: AccountProviderProps) => {
  const { loggedIn, loggedOut } = useAppSelector((state) => state.user);
  const { i18n } = useTranslation();
  const { appLoading } = useAppSelector((state) => state.app);
  const history = useHistory();
  const sessionID = Cookies.load(COOKIE_SID);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const redirectToLogin = (title: string, message: string) => {
      dispatch(setIsLoadingAction(false));

      toastr.error(title, message);

      Cookies.remove(COOKIE_SID);
      history.replace(LOGIN);
    };

    const getUserDetails = () => {
      API.get(ACCOUNT_ENDPOINTS.DETAILS)
        .then((resp) => {
          const { user } = resp.data;

          dispatch(setLoggedInAction(true));
          dispatch(setIsLoadingAction(false));
          dispatch(setDetailsAction(user));
        })
        .catch((err) => {
          const { data } = err.response || {};
          if (data) {
            redirectToLogin(data.title, data.message);
          }
        });
    };
    if (loggedOut || (loggedIn && !loggedOut)) {
      return;
    }

    if (sessionID) {
      getUserDetails();
    } else {
      dispatch(setIsLoadingAction(false));
    }
  }, [dispatch, history, loggedIn, loggedOut, sessionID, i18n.language]);

  // return children;
  return appLoading ? <AppLoader /> : children;
};

export default AccountProvider;
