import ReactSelect, { components } from 'react-select';
import { cnb } from 'cnbuilder';
import { useTranslation } from 'react-i18next';
import { cloneDeep as _cloneDeep } from 'lodash';

import { resolveValue } from 'Utils/common';

import DropdownIndicator from 'Components/@Base/SelectDropdownIndicator';
import MultiValueContainer from 'Components/@Base/SelectMultiValueContainer';
import MultiValueLabel from 'Components/@Base/SelectMultiValueLabel';
import MultiValueRemove from 'Components/@Base/SelectMultiValueRemove';

import styles from './Select.module.scss';

type SelectProps = {
  className?: string;
  defaultValue?: any;
  value?: any;
  findValue?: any;
  options?: Array<{
    label?: string;
    id?: string | number;
    value?: any;
    name?: string;
    image?: string;
  }>;
  onChange?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
};

const Select = ({ className, defaultValue, value, findValue, options, isMulti, ...rest }: SelectProps) => {
  const { t } = useTranslation();

  const findVal = typeof findValue === 'function' ? findValue : resolveValue;
  let val = _cloneDeep(value);

  if (isMulti && Array.isArray(val)) {
    val = val.map((v) => v);
  } else if (val || val === 0) {
    val = findVal(options, value);
  } else {
    val = null;
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{t('Nie options')}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <ReactSelect
      components={{ DropdownIndicator, MultiValueContainer, MultiValueLabel, MultiValueRemove, NoOptionsMessage }}
      className={cnb(styles.Select, className)}
      classNamePrefix="Select"
      options={options}
      defaultValue={options ? findVal(options, defaultValue) : ''}
      value={val}
      getOptionValue={(option) => (typeof option.value !== 'undefined' ? option.value : option.id)}
      isMulti={isMulti}
      {...rest}
    />
  );
};

Select.defaultProps = {
  className: '',
  defaultValue: '',
  value: '',
  options: [],
  findValue: undefined,
  onChange: undefined,
  isMulti: false,
  isClearable: false,
  isSearchable: false,
};

export default Select;
