import update from 'immutability-helper';
import * as actionTypes from 'Store/offers/actionTypes';

type OffersState = {
  offers: Array<{
    id: number;
    offer: string;
    created_at: string;
    created_by: {
      id: number;
      first_name: string;
      last_name: string;
      user_id: number;
    };
  }>;
  isReady: boolean;
  total: number;
};

const initialState: OffersState = {
  offers: [],
  isReady: false,
  total: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OFFERS: {
      const { offers, total } = action.payload;

      return update(state, {
        offers: {
          $set: offers,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_OFFERS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_OFFERS: {
      return update(state, {
        $set: initialState,
      });
    }
    default:
      return state;
  }
};
