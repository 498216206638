export const schemasDataFinance = [
  {
    title: 'Finanse 1.0',
    type: 'finanse',
    id: 53,
    level: 'podstawowy',
    autor: 'Karol Froń',
    created_at: '2023-08-09',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.1',
    type: 'finanse',
    id: 54,
    level: 'zaawansowany',

    autor: 'Karol Froń',
    created_at: '2023-08-09',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.2',
    type: 'finanse',
    id: 55,
    level: 'melancholik',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.3',
    type: 'finanse',
    id: 56,
    level: 'choleryk',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.4',
    type: 'finanse',
    id: 57,
    level: 'sangwinik',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
];
export const schemasDataSafety = [
  {
    title: 'Ubezpieczenia 1.0',
    type: 'safety',
    id: 58,
    level: 'podstawowy',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.1',
    type: 'safety',
    id: 59,
    level: 'zaawansowany',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.2',
    type: 'safety',
    id: 60,
    level: 'melancholik',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.3',
    type: 'safety',
    id: 61,
    level: 'choleryk',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.4',
    type: 'safety',
    id: 62,
    level: 'sangwinik',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
];
