import { useAppSelector } from 'Hooks/redux-hooks';

import DefaultLayout from 'Layouts/DefaultLayout';
import WithSidebarLayout from 'Layouts/WithSidebarLayout';

import Logo from 'Components/@Shared/Logo';
import LoaderWithMessage from 'Components/@Shared/LoaderWithMessage';
import Loader from 'Components/@Shared/Loader';

import styles from './AppLoader.module.scss';

const AppLoader = () => {
  const { loggedIn } = useAppSelector((state) => state.user);
  const Layout = loggedIn ? WithSidebarLayout : DefaultLayout;
  const content = loggedIn ? (
    <Loader minHeight="500px" />
  ) : (
    <>
      <Logo />
      <LoaderWithMessage
        messageProps={{ className: styles.AppLoader__Message, message: 'Loading...' }}
        loaderProps={{ className: styles.AppLoader__Message }}
      />
    </>
  );

  return (
    <Layout>
      <div className={styles.AppLoader}>{content}</div>
    </Layout>
  );
};

export default AppLoader;
