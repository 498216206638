import { toastr } from 'react-redux-toastr';

import { cloneDeep as _cloneDeep, pickBy as _pickBy, identity as _identity } from 'lodash';
import queryString from 'query-string';
import moment from 'moment';
import FileType from 'file-type/browser';

import { DATE_FORMAT, DATE_FORMAT_WITHOUT_HOURS, DATE_FORMAT_WITHOUT_SECONDS } from 'Constants';
import * as TICKET_STATUSES from 'Constants/ticket';

export const getCountryIso = (str: string) => str.split('-')[1].toLowerCase();

export const ensureNumber = (value) => (Number.isNaN(value) ? undefined : value);

export const ensureNumberForCount = (value) => (Number.isNaN(value) ? 0 : value);

export const isNotEmptyArray = (arr: Array<any>) => arr && arr.length && Array.isArray(arr);

export const isNotEmptyObject = (obj: {}) => Object.values(obj).some((v) => v !== null && typeof v !== 'undefined');

export const formatRFC3339FromDateString = (date: string) => {
  if (!date) {
    return date;
  }
  const d = new Date(date);
  const momentDate = moment(d.toISOString());

  return momentDate.format('YYYY-MM-DD');
};

export const formatLocalFrom = (date: string) => {
  const momentDate = moment(date);
  if (moment(date).isValid()) {
    return momentDate.local().format(DATE_FORMAT);
  }
  return date;
};

export const formatLocalFromUTC = (date: string) => {
  const momentDate = moment.utc(date);

  return momentDate.local().format(DATE_FORMAT);
};

export const formatLocalFromWithoutHours = (date: string) => {
  const momentDate = moment(date);
  if (moment(date).isValid()) {
    return momentDate.local().format(DATE_FORMAT_WITHOUT_HOURS);
  }
  return date;
};

export const formatLocalFromWithoutSeconds = (date: string) => {
  const momentDate = moment(date);
  if (moment(date).isValid()) {
    return momentDate.local().format(DATE_FORMAT_WITHOUT_SECONDS);
  }
  return date;
};

export const extractContentFromHtml = (html) => {
  return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
};

export const formDataToString = (data: {
  status?: Array<{
    label: string;
    value: string;
  }>;
  created_at_from?: string;
  created_at_to?: string;
  created_at?: string;
  term_from?: string;
  term_to?: string;
  date?: string;
}) => {
  const formData: any = _pickBy(data, (v: string | null) => {
    let val = v;

    if (Array.isArray(val) && val.length === 0) {
      val = null;
    }

    return _identity(val);
  });

  if ('status' in formData) {
    formData.status = formData.status.map((i) => i.value);
  }

  if ('created_at_from' in formData) {
    formData.created_at_from = formatRFC3339FromDateString(formData.created_at_from);
  }

  if ('created_at_to' in formData) {
    formData.created_at_to = formatRFC3339FromDateString(formData.created_at_to);
  }

  if ('created_at' in formData) {
    formData.created_at = formatRFC3339FromDateString(formData.created_at);
  }

  if ('term_from' in formData) {
    formData.term_from = formatRFC3339FromDateString(formData.term_from);
  }

  if ('term_to' in formData) {
    formData.term_to = formatRFC3339FromDateString(formData.term_to);
  }

  if ('date' in formData) {
    formData.date = formatRFC3339FromDateString(formData.date);
  }

  return queryString.stringify(formData, { arrayFormat: 'bracket' });
};

export const displayFormattedNumberToFixed = (amount, locale = 'en-US', fractionDigits = 2) => {
  if (!amount) {
    return 0;
  }

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);
};

export const formatUnderscoreIso = (str: string) => str.replace('-', '_');

export const updateFilters = (filters: any, payloadFilters: any) => {
  const result = _cloneDeep(filters);

  Object.keys(payloadFilters).forEach((key) => {
    result[key].value = payloadFilters[key] || '';
  });

  return result;
};

export const prepareFilters = (filters: { [key: string]: any }) => {
  const result = _cloneDeep(filters);

  Object.keys(result).forEach((key) => {
    if (key === 'created_at_from' || key === 'created_at_to') {
      result[key] = result[key].value ? new Date(result[key].value) : '';
    } else if (key === 'term_from' || key === 'term_to') {
      result[key] = result[key].value ? new Date(result[key].value) : '';
    } else {
      result[key] = result[key].value;
    }
  });

  return result;
};

export const resolveValue = (options: Array<any>, value: any) =>
  options.find((o) => (typeof o.value !== 'undefined' ? o.value === value : o.id === value));

export const readFileAsync = async (file: any) => {
  const mime = await FileType.fromBlob(file);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64 = reader.result?.toString().split(',');
      const data = base64 && base64[1];
      let type = base64 && base64[0];
      type = type?.replace(/:.*;/, `:${mime ? mime.mime : file.type};`);

      const item = {
        file_name: file.name,
        src: `${type},${data}`,
      };

      resolve(item);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

//       TOAST
const handleUnprocessableEntity = (errorData, setError) => {
  Object.keys(errorData.message).forEach((field) => {
    setError(field, {
      type: 'typeError',
      message: errorData.message[field],
    });
  });
};
const toastrMessageOptions = {
  timeOut: 5000,
};

const handleLocked = (status, errorData) => {
  toastr.info(status, errorData);
};

const handleNotAcceptable = (status, errorData) => {
  toastr.warning(status, errorData);
};

const handleDefault = (status, errorData) => {
  toastr.error(status?.toString(), errorData, toastrMessageOptions);
};

const statusHandlers = {
  422: handleUnprocessableEntity,
  423: handleLocked,
  406: handleNotAcceptable,
  default: handleDefault,
};

export const createToastr = (errorData, status, setError = null) => {
  const handler = statusHandlers[status] || statusHandlers.default;
  handler(status, errorData, setError);
};
// uppercase first letter
export function ucFirst(str) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
}

export const isNewTicket = (status: number, isAdmin = false) => {
  if (status === TICKET_STATUSES.TICKET_STATUS_CLOSED) {
    return false;
  }

  return isAdmin
    ? status === TICKET_STATUSES.TICKET_STATUS_SUPPORT_WAITING ||
        status === TICKET_STATUSES.TICKET_STATUS_CUSTOMER_REPLIED
    : status === TICKET_STATUSES.TICKET_STATUS_CUSTOMER_WAITING ||
        status === TICKET_STATUSES.TICKET_STATUS_SUPPORT_REPLIED;
};
