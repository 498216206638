import update from 'immutability-helper';
import * as actionTypes from 'Store/coordinatorUser/actionTypes';

type UsersState = {
  users: Array<{
    id: number;
    email: string;
    role: string;
    owned_groups?: any;
    groups?: any;
  }>;
  isReady: boolean;
  // total: number;
};

const initialState: UsersState = {
  users: [],
  isReady: false,
  // total: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USERS: {
      const { users } = action.payload;

      return update(state, {
        users: {
          $set: users,
        },
        // total: {
        //   $set: total,
        // },
      });
    }
    case actionTypes.SET_USERS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_USERS: {
      return update(state, {
        $set: initialState,
      });
    }
    default:
      return state;
  }
};
