import { Link } from 'react-router-dom';

import logo from 'Assets/images/logo_MeMentor1.png';

import styles from './Logo.module.scss';

const Logo = () => (
  <div className={styles.Logo}>
    <Link to="/">
      <img alt="logo" src={logo} className={styles.Logo__logo} />
    </Link>
  </div>
);

export default Logo;
