import { cnb } from 'cnbuilder';

import styles from './Label.module.scss';

type LabelProps = {
  className?: string;
  label?: any;
  htmlFor?: any;
  isRequired?: boolean;
};

const Label = ({ className, label, htmlFor, isRequired, ...rest }: LabelProps) => (
  <label className={cnb(styles.Label, 'mb-1', className)} htmlFor={htmlFor} {...rest}>
    {isRequired ? `${label} *` : label}
  </label>
);

Label.defaultProps = {
  className: '',
  label: '',
  htmlFor: undefined,
  isRequired: false,
};

export default Label;
