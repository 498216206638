import update from 'immutability-helper';
import * as actionTypes from 'Store/settings/actionTypes';

type SettingsState = {
  settings: Array<{
    description: string;
    id: number;
    key: string;
    updated_at: string;
    value: string;
  }>;
  isReady: boolean;
  total: number;
};

const initialState: SettingsState = {
  settings: [],
  isReady: false,
  total: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SETTINGS: {
      const { settings, total } = action.payload;

      return update(state, {
        settings: {
          $set: settings,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_SETTINGS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    default:
      return state;
  }
};
