import API from 'Lib/axios';
import { Dispatch } from '@reduxjs/toolkit';

import { ANALITICS_ENDPOINTS } from 'Constants/endpoints';

import * as actionTypes from 'Store/coordinatorAnalytics/actionTypes';

/** SET_REPORTS_BY_USERS #################################################################################################### */

export const setReportsByUsersAction = (reports) => ({
  type: actionTypes.SET_REPORTS_BY_USERS,
  payload: {
    reports,
  },
});

export const fetchReportsByUsers = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_BY_USERS_ALL);
};

export const getReportsByUsers = () => {
  return (dispatch: Dispatch) => {
    fetchReportsByUsers().then((res) => {
      const { reports } = res.data.data;
      dispatch(setReportsByUsersAction(reports));
    });
  };
};

/** SET_REPORTS_BY_COMPLETION_PERC #################################################################################################### */

export const setReportsByCompletionPercAction = (reports) => ({
  type: actionTypes.SET_REPORTS_BY_COMPLETION_PERC,
  payload: {
    reports,
  },
});

export const fetchReportsByCompletionPerc = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_BY_COMPLETION_PERC_ALL);
};

export const getReportsByCompletionPerc = () => {
  return (dispatch: Dispatch) => {
    fetchReportsByCompletionPerc().then((res) => {
      const { group_by_completion_perc } = res.data.data;
      dispatch(setReportsByCompletionPercAction(group_by_completion_perc));
    });
  };
};

/** SET_REPORTS_BY_STAGE #################################################################################################### */

export const setReportsByStageAction = (reports) => ({
  type: actionTypes.SET_REPORTS_BY_STAGE,
  payload: {
    reports,
  },
});

export const fetchReportsByStage = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_BY_STAGE_ALL);
};

export const getReportsByStage = () => {
  return (dispatch: Dispatch) => {
    fetchReportsByStage().then((res) => {
      const { reports } = res.data.data;

      dispatch(setReportsByStageAction(reports));
    });
  };
};

/** SET_REPORTS_BY_TIME #################################################################################################### */

export const setReportsByTimeAction = (reports) => ({
  type: actionTypes.SET_REPORTS_BY_TIME,
  payload: {
    reports,
  },
});

export const fetchReportsByTime = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_BY_TIME_ALL);
};

export const getReportsByTime = () => {
  return (dispatch: Dispatch) => {
    fetchReportsByTime().then((res) => {
      const { reports } = res.data.data;

      dispatch(setReportsByTimeAction(reports));
    });
  };
};

/** SET_REPORTS_BY_SCHEMA #################################################################################################### */

export const setReportsBySchemaAction = (reports) => ({
  type: actionTypes.SET_REPORTS_BY_SCHEMA,
  payload: {
    reports,
  },
});

export const fetchReportsBySchema = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_BY_SCHEMA_ALL);
};

export const getReportsBySchema = () => {
  return (dispatch: Dispatch) => {
    fetchReportsBySchema().then((res) => {
      const { reports } = res.data.data;

      dispatch(setReportsBySchemaAction(reports));
    });
  };
};

/** SET_REPORTS_FAILURE #################################################################################################### */

export const setReportsFailureAction = (reports) => ({
  type: actionTypes.SET_REPORTS_FAILURE,
  payload: {
    reports,
  },
});

export const fetchReportsFailure = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_FAILURE);
};

export const getReportsFailure = () => {
  return (dispatch: Dispatch) => {
    fetchReportsFailure().then((res) => {
      const { reports } = res.data;
      dispatch(setReportsFailureAction(reports));
    });
  };
};

/** SET_REPORTS_SUCCESS #################################################################################################### */

export const setReportsSuccessAction = (reports) => ({
  type: actionTypes.SET_REPORTS_SUCCESS,
  payload: {
    reports,
  },
});

export const fetchReportsSuccess = () => {
  return API.get(ANALITICS_ENDPOINTS.ANALITICS_REPORTS_SUCCESS);
};

export const getReportsSuccess = () => {
  return (dispatch: Dispatch) => {
    fetchReportsSuccess().then((res) => {
      const { reports } = res.data;
      dispatch(setReportsSuccessAction(reports));
    });
  };
};
