import { Children, cloneElement, useCallback, useState, useRef } from 'react';
import { cnb } from 'cnbuilder';
import Collapse from '@kunukn/react-collapse';

import useEventListener from 'Hooks/use-event-listener';

import styles from './Dropdown.module.scss';

type DropdownProps = {
  className?: string;
  header: string | JSX.Element;
  children: JSX.Element;
  isAction?: boolean;
};

const Dropdown = ({ className, header, children, isAction = false }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collapseState, setCollapseState] = useState('');
  const dropdownRef = useRef<any>(null);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  const handleClickOutside = useCallback(
    (event) => {
      const { target } = event;
      const { current: dropdown } = dropdownRef;

      if (dropdown && isOpen && !dropdown.contains(target)) {
        handleToggle();
      }
    },
    [dropdownRef, isOpen, handleToggle],
  );

  const handleOnChange = ({ state }: { state: string }) => {
    setCollapseState(state);
  };

  useEventListener('click', handleClickOutside);

  return (
    <div
      ref={dropdownRef}
      className={cnb(
        isAction ? styles.Dropdown_action : styles.Dropdown,
        isOpen && styles.Dropdown_isExpanded,
        className,
        `${collapseState}Drop`,
      )}
    >
      <button
        className={isAction ? styles.Dropdown__header_action : styles.Dropdown__header}
        type="button"
        onClick={handleToggle}
      >
        {header}
      </button>
      <Collapse
        className={isAction ? styles.Dropdown__collapse_action : styles.Dropdown__collapse}
        isOpen={isOpen}
        onChange={handleOnChange}
      >
        <div className={styles.Dropdown__content}>
          {Children.map(children, (child) => {
            return child
              ? cloneElement(child, {
                  onClick: () => {
                    handleToggle();

                    if (typeof child.props.onClick === 'function') {
                      child.props.onClick();
                    }
                  },
                })
              : null;
          })}
        </div>
      </Collapse>
    </div>
  );
};

Dropdown.defaultProps = {
  className: '',
  isAction: false,
};

export default Dropdown;
