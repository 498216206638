import { cnb } from 'cnbuilder';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';

import { logout } from 'Store/user/actions';

import { PROFILE, USER_CHANGE_PASSWORD } from 'Constants/routes';

import DropdownWithIcon from 'Components/@Shared/DropdownWithIcon';

import styles from './AccountDropdown.module.scss';

const AccountDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user_details } = useAppSelector((state) => state.user);

  return (
    <DropdownWithIcon className={cnb(styles.AccountDropdown, 'mx-3')} header={user_details?.first_name || t('Konto')}>
      <>
        <Link to={PROFILE}>{t('Mój profil')}</Link>
        <Link to={USER_CHANGE_PASSWORD}>{t('Zmień hasło')}</Link>
        <button type="button" onClick={() => dispatch<any>(logout(true))}>
          {t('Wyloguj')}
        </button>
      </>
    </DropdownWithIcon>
  );
};

export default AccountDropdown;
