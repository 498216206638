import * as actionTypes from 'Store/modal/actionTypes';

export const showModalAction = (modal: any, props: any) => ({
  type: actionTypes.SHOW,
  payload: {
    modal,
    props: props || {},
  },
});

export const hideModalAction = (modal: any) => ({
  type: actionTypes.HIDE,
  payload: {
    modal,
  },
});

export const destroyModalAction = (modal: any) => ({
  type: actionTypes.DESTROY,
  payload: {
    modal,
  },
});
