import { AnyAction } from 'redux';
import { Dispatch, ThunkAction } from '@reduxjs/toolkit';
import * as actionTypes from 'Store/app/actionTypes';

import API from 'Lib/axios';

import { RootState } from 'Store/store';

import { LOCALE_ENDPOINTS, HELPER_ENDPOINTS } from 'Constants/endpoints';

import { COOKIE_ITEMS_PER_PAGE } from 'Utils/cookies';

/** LOADING ############################################################################################################ LOADING */
export const setIsLoadingAction = (appLoading: boolean) => ({
  type: actionTypes.SET_IS_LOADING,
  payload: {
    appLoading,
  },
});

export const setIsLoading = (appLoading: boolean): ThunkAction<void, RootState, void, AnyAction> => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    if (typeof appLoading !== 'undefined') {
      dispatch(setIsLoadingAction(appLoading));
    } else {
      const { appLoading: loading } = getState().app;

      dispatch(setIsLoadingAction(!loading));
    }
  };
};

/** SIDEBAR ############################################################################################################ SIDEBAR */
export const toggleSidebarAction = (sidebarOpen: boolean) => ({
  type: actionTypes.TOGGLE_SIDEBAR,
  payload: {
    sidebarOpen,
  },
});

/** ITEMS PER PAGE ##################################################################################################### ITEMS PER PAGE */
export const setItemsPerPageAction = (itemsPerPage: number) => ({
  type: actionTypes.SET_ITEMS_PER_PAGE,
  payload: {
    itemsPerPage,
  },
});

export const setItemsPerPage = (itemsPerPage: number) => {
  return (dispatch: Dispatch) => {
    window.localStorage.setItem(COOKIE_ITEMS_PER_PAGE, itemsPerPage.toString());

    dispatch(setItemsPerPageAction(itemsPerPage));
  };
};

/** LANGUAGES ##################################################################################################### LANGUAGES */

export const setLanguagesAction = (languages) => ({
  type: actionTypes.SET_LANGUAGES,
  payload: {
    languages,
  },
});

export const fetchLanguages = () => {
  return API.get(LOCALE_ENDPOINTS.LANGUAGES);
};

export const getLanguages = () => {
  return (dispatch: Dispatch) => {
    fetchLanguages().then((res) => {
      const { countries } = res.data;

      dispatch(setLanguagesAction(countries));
    });
  };
};

/**  CUSTOMER_STATUSES #####################################################################################################  */

export const setCustomerStatusesAction = (customer_statuses) => ({
  type: actionTypes.SET_CUSTOMER_STATUSES,
  payload: {
    customer_statuses,
  },
});

export const fetchCustomerStatuses = () => {
  return API.get(HELPER_ENDPOINTS.CUSTOMER_STATUSES);
};

export const getCustomerStatuses = () => {
  return (dispatch: Dispatch) => {
    fetchCustomerStatuses().then((res) => {
      const { statuses: customer_statuses } = res.data;

      dispatch(setCustomerStatusesAction(customer_statuses));
    });
  };
};

/** ACCOUNTS #####################################################################################################  */

export const setAccountsAction = (accounts) => ({
  type: actionTypes.SET_ACCOUNTS,
  payload: {
    accounts,
  },
});

export const fetchAccounts = () => {
  return API.get(HELPER_ENDPOINTS.ACCOUNTS);
};

export const getAccounts = () => {
  return (dispatch: Dispatch) => {
    fetchAccounts().then((res) => {
      const { accounts } = res.data;

      dispatch(setAccountsAction(accounts));
    });
  };
};

/** SUMMARY #####################################################################################################  */

export const setSummaryAction = (summary) => ({
  type: actionTypes.SET_SUMMARY,
  payload: {
    summary,
  },
});

export const fetchSummary = () => {
  return API.get(HELPER_ENDPOINTS.SUMMARY);
};

export const getSummary = () => {
  return (dispatch: Dispatch) => {
    fetchSummary().then((res) => {
      const summary = res.data;

      dispatch(setSummaryAction(summary));
    });
  };
};

/** TYPES #####################################################################################################  */

export const setCustomerTypesAction = (types) => ({
  type: actionTypes.SET_CUSTOMER_TYPES,
  payload: {
    types,
  },
});

export const fetchCustomerTypes = () => {
  return API.get(HELPER_ENDPOINTS.CUSTOMER_TYPES);
};

export const getCustomerTypes = () => {
  return (dispatch: Dispatch) => {
    fetchCustomerTypes().then((res) => {
      const { types } = res.data;

      dispatch(setCustomerTypesAction(types));
    });
  };
};
/** SOURCE #####################################################################################################  */

export const setCustomerSourcesAction = (sources) => ({
  type: actionTypes.SET_CUSTOMER_SOURCES,
  payload: {
    sources,
  },
});

export const fetchCustomerSources = () => {
  return API.get(HELPER_ENDPOINTS.CUSTOMER_SOURCES);
};

export const getCustomerSources = () => {
  return (dispatch: Dispatch) => {
    fetchCustomerSources().then((res) => {
      const { sources } = res.data;

      dispatch(setCustomerSourcesAction(sources));
    });
  };
};

/** HALLS #####################################################################################################  */

export const setHallsAction = (halls) => ({
  type: actionTypes.SET_HALLS,
  payload: {
    halls,
  },
});

export const fetchHalls = () => {
  return API.get(HELPER_ENDPOINTS.HALLS);
};

export const getHalls = () => {
  return (dispatch: Dispatch) => {
    fetchHalls().then((res) => {
      const { halls } = res.data;

      dispatch(setHallsAction(halls));
    });
  };
};

/** CUSTOMERS #####################################################################################################  */

export const setCustomersAction = (customers) => ({
  type: actionTypes.SET_CUSTOMERS,
  payload: {
    customers,
  },
});

export const fetchCustomers = () => {
  return API.get(HELPER_ENDPOINTS.CUSTOMERS);
};

export const getCustomers = () => {
  return (dispatch: Dispatch) => {
    fetchCustomers().then((res) => {
      const { customers } = res.data;

      dispatch(setCustomersAction(customers));
    });
  };
};

/** REQUEST_STATUSES #####################################################################################################  */

export const setRequestStatusesAction = (request_statuses) => ({
  type: actionTypes.SET_REQUEST_STATUSES,
  payload: {
    request_statuses,
  },
});

export const fetchRequestStatuses = () => {
  return API.get(HELPER_ENDPOINTS.REQUEST_STATUSES);
};

export const getRequestStatuses = () => {
  return (dispatch: Dispatch) => {
    fetchRequestStatuses().then((res) => {
      const { statuses: request_statuses } = res.data;

      dispatch(setRequestStatusesAction(request_statuses));
    });
  };
};

/**  ORDER_STATUSES  #####################################################################################################  */

export const setOrderStatusesAction = (order_statuses) => ({
  type: actionTypes.SET_ORDER_STATUSES,
  payload: {
    order_statuses,
  },
});

export const fetchOrderStatuses = () => {
  return API.get(HELPER_ENDPOINTS.ORDER_STATUSES);
};

export const getOrderStatuses = () => {
  return (dispatch: Dispatch) => {
    fetchOrderStatuses().then((res) => {
      const { statuses: order_statuses } = res.data;

      dispatch(setOrderStatusesAction(order_statuses));
    });
  };
};

/** EVENTS #####################################################################################################  */

export const setEventsAction = (events) => ({
  type: actionTypes.SET_EVENTS,
  payload: {
    events,
  },
});

export const fetchEvents = () => {
  return API.get(HELPER_ENDPOINTS.EVENTS);
};
// function to get events
export const getEvents = () => {
  return (dispatch: Dispatch) => {
    fetchEvents().then((res) => {
      const { events } = res.data;

      dispatch(setEventsAction(events));
    });
  };
};

/** AREA_CODES #####################################################################################################  */

export const setAreaCodesAction = (area_codes) => ({
  type: actionTypes.SET_AREA_CODES,
  payload: {
    area_codes,
  },
});

export const fetchAreaCodes = () => {
  return API.get(HELPER_ENDPOINTS.AREA_CODES);
};

export const getAreaCodes = () => {
  return (dispatch: Dispatch) => {
    fetchAreaCodes().then((res) => {
      const { area_codes } = res.data;

      dispatch(setAreaCodesAction(area_codes));
    });
  };
};

/** PAY_STATUSES #####################################################################################################  */

export const setPayStatusesAction = (pay_statuses) => ({
  type: actionTypes.SET_PAY_STATUSES,
  payload: {
    pay_statuses,
  },
});

export const fetchPayStatuses = () => {
  return API.get(HELPER_ENDPOINTS.PAY_STATUSES);
};

export const getPayStatuses = () => {
  return (dispatch: Dispatch) => {
    fetchPayStatuses().then((res) => {
      const { pay_statuses } = res.data;

      dispatch(setPayStatusesAction(pay_statuses));
    });
  };
};

/** not interested status #####################################################################################################  */

export const setSubStatusesNotInterestedAction = (substatuses) => ({
  type: actionTypes.SET_SUB_STATUS_NOT_INTERESTED,
  payload: {
    substatuses,
  },
});

export const fetchSubStatusesNotInterested = () => {
  return API.get(HELPER_ENDPOINTS.SUB_STATUS_NOT_INTERESTED);
};

export const getSubStatusesNotInterested = () => {
  return (dispatch: Dispatch) => {
    fetchSubStatusesNotInterested().then((res) => {
      const { substatuses } = res.data;

      dispatch(setSubStatusesNotInterestedAction(substatuses));
    });
  };
};

/** DATABASE STATUSES #####################################################################################################  */

export const setDatabaseStatusesAction = (database_statuses) => ({
  type: actionTypes.SET_DATABASE_STATUSES,
  payload: {
    database_statuses,
  },
});

export const fetchDatabaseStatuses = () => {
  return API.get(HELPER_ENDPOINTS.DATABASE_STATUSES);
};

export const getDatabaseStatuses = () => {
  return (dispatch: Dispatch) => {
    fetchDatabaseStatuses().then((res) => {
      const { statuses: database_statuses } = res.data;

      dispatch(setDatabaseStatusesAction(database_statuses));
    });
  };
};

/** Leads STATUSES #####################################################################################################  */

export const setLeadStatusesAction = (lead_statuses) => ({
  type: actionTypes.SET_LEAD_STATUSES,
  payload: {
    lead_statuses,
  },
});

export const fetchLeadStatuses = () => {
  return API.get(HELPER_ENDPOINTS.LEAD_STATUSES);
};

export const getLeadStatuses = () => {
  return (dispatch: Dispatch) => {
    fetchLeadStatuses().then((res) => {
      const { statuses: lead_statuses } = res.data;

      dispatch(setLeadStatusesAction(lead_statuses));
    });
  };
};

/** DATABASES #####################################################################################################  */

export const setDatabasesAction = (databases) => ({
  type: actionTypes.SET_DATABASES,
  payload: {
    databases,
  },
});

export const fetchDatabases = () => {
  return API.get(HELPER_ENDPOINTS.DATABASES);
};

export const getDatabases = () => {
  return (dispatch: Dispatch) => {
    fetchDatabases().then((res) => {
      const { databases } = res.data;

      dispatch(setDatabasesAction(databases));
    });
  };
};

/** PROVINCES #####################################################################################################  */

export const setProvincesAction = (provinces) => ({
  type: actionTypes.SET_PROVINCES,
  payload: {
    provinces,
  },
});

export const fetchProvinces = () => {
  return API.get(HELPER_ENDPOINTS.PROVINCES);
};

export const getProvinces = () => {
  return (dispatch: Dispatch) => {
    fetchProvinces().then((res) => {
      const { provinces } = res.data;

      dispatch(setProvincesAction(provinces));
    });
  };
};

/** LEGAL-FORMS #####################################################################################################  */

export const setLegalFormsAction = (legal_forms) => ({
  type: actionTypes.SET_LEGAL_FORMS,
  payload: {
    legal_forms,
  },
});

export const fetchLegalForms = () => {
  return API.get(HELPER_ENDPOINTS.LEGAL_FORMS);
};

export const getLegalForms = () => {
  return (dispatch: Dispatch) => {
    fetchLegalForms().then((res) => {
      const { legal_forms } = res.data;

      dispatch(setLegalFormsAction(legal_forms));
    });
  };
};

/** PKD-CODES #####################################################################################################  */

export const setPKDCodesAction = (pkd_codes) => ({
  type: actionTypes.SET_PKD_CODES,
  payload: {
    pkd_codes,
  },
});

export const fetchPKDCodes = () => {
  return API.get(HELPER_ENDPOINTS.PKD_CODES);
};

export const getPKDCodes = () => {
  return (dispatch: Dispatch) => {
    fetchPKDCodes().then((res) => {
      const { pkd_codes } = res.data;

      dispatch(setPKDCodesAction(pkd_codes));
    });
  };
};
