export const ROLE_TYPE_ADMIN = 1;
export const ROLE_TYPE_COORDINATOR = 'coordinator';
export const ROLE_TYPE_USER = 'user';

export const TOASTR_OPTIONS = {
  timeOut: 6000,
  newestOnTop: true,
  preventDuplicates: true,
  position: 'top-right',
  transitionIn: 'bounceInDown',
  transitionOut: 'fadeOut',
  progressBar: true,
  closeOnToastrClick: true,
};

export const PASSWORD_REGEXES = {
  minLength: 8,
  lowerCase: /[a-z]/,
  upperCase: /[A-Z]/,
  digit: /\d/,
  special: /(_|[^\w\d ])/,
};

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_WITHOUT_HOURS = 'YYYY-MM-DD';

export const MAX_UPLOAD_SIZE_BYTES = 8000000;
export const MAX_UPLOAD_SIZE_MEGABYTES = MAX_UPLOAD_SIZE_BYTES / 1000000;
