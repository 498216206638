import { components } from 'react-select';
import { MdKeyboardArrowDown } from 'react-icons/all';

const SelectDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <MdKeyboardArrowDown style={{ height: '20px', width: '20px' }} />
    </components.DropdownIndicator>
  );
};

export default SelectDropdownIndicator;
