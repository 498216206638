import axios from 'axios';
import Cookies from 'react-cookies';
import { toastr } from 'react-redux-toastr';

import history from 'Lib/history';

import { LOGIN } from 'Constants/routes';

import { API_URL } from 'config';
import { COOKIE_SID } from 'Utils/cookies';

const API = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  baseURL: API_URL,
});

API.interceptors.request.use(
  (request) => {
    const token = Cookies.load(COOKIE_SID);
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => {
    // if (response.data?.status) {
    //   if (response.status === 200 || response.status === 201) {
    //     // toastr.success(response.data?.message, 'success');
    //   }

    //   if (response.status === 203) {
    //     // toastr.info(response.data?.message, 'info');
    //   }
    // }

    return response;
  },
  (error) => {
    // const { error_message, error_code } = error.response.data || false;
    console.log(error);
    // if (error_code && error_code === 401) {
    //   toastr.error(error_code, error_message);

    //   Cookies.remove(COOKIE_SID);

    //   history.replace(LOGIN);
    // }

    return Promise.reject(error);
  },
);

export default API;
