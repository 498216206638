import * as yup from 'yup';
import { get as _get } from 'lodash';

yup.addMethod(yup.array, 'unique', function (path, message) {
  return this.test('unique', message, function (list: any) {
    const pathParts = path.split('.');
    const rootPath = pathParts[0];
    const propPath = pathParts[1];

    const mapper = (x: {}) => _get(x, propPath);
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;

    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l: any, i: any) => mapper(l) !== set[i]);
    return this.createError({ path: `${rootPath}[${idx}].${propPath}`, message });
  });
});

export default yup;
