export const ACCOUNT_ENDPOINTS = {
  REGISTER: '/users/register',
  SUMMARY: '/users/account/summary',
  CHECK_TOKEN: '/users/account/check-token',
  REMIND_PASSWORD: '/users/password',
  RESET_PASSWORD: '/users/password',
  USER_CHANGE_PASSWORD: '/users/account/password/change',
  VERIFICATION: '/users/confirmation',
  VERIFICATION_EMAIL: '/users/account/verification/email',
  RESEND_EMAIL_TOKEN: '/users/account/resend-email-token',
  LOGIN: '/users/session',
  LOGOUT: '/users/session',
  DETAILS: '/user_profile',
};

// TODO: change endpoints
export const REPORTS_ENDPOINTS = {
  REPORTS: '/reports',
  REPORTS_ADD: '/reports',
};
export const COORDINATOR_REPORTS_ENDPOINTS = {
  REPORTS: '/reports',
  REPORTS_ADD: '/reports',
};

// TODO: change endpoints
export const SCHEMAS_ENDPOINTS = {
  SCHEMAS: '/schemas',
};
// TODO: change endpoints
export const COORDINATOR_SCHEMAS_ENDPOINTS = {
  SCHEMAS: '/schemas',
};

// TODO: change endpoints
export const USER_ENDPOINTS = {
  USERS: '/user/all',
};

// TODO: change endpoints
export const ANALITICS_ENDPOINTS = {
  ANALITICS_REPORTS_BY_USERS_ALL: '/analytics/reports_by_users/all',
  ANALITICS_REPORTS_BY_COMPLETION_PERC_ALL: '/analytics/reports_by_completion_perc/all',
  ANALITICS_REPORTS_BY_STAGE_ALL: '/analytics/reports_by_stage/all',
  ANALITICS_REPORTS_BY_TIME_ALL: '/analytics/reports_by_time/all',
  ANALITICS_REPORTS_BY_SCHEMA_ALL: '/analytics/reports_by_schema/all',
  ANALITICS_REPORTS_FAILURE: '/analytics/failure',
  ANALITICS_REPORTS_SUCCESS: '/analytics/success',
};

export const TICKETS_ENDPOINTS = {
  CREATE: '/user/ticket',
  REPLY: '/user/ticket/reply',
  CLOSE: '/user/ticket/close',
  TICKET: '/user/ticket',
  TICKETS: '/user/tickets',
};

// TODO: change endpoints
export const GROUPS_ENDPOINTS = {
  // GROUPS_REPORTS: '/groups/{id}',
  // GROUPS_SCHEMAS: '/groups/{id}',
  GROUPS: '/groups',
  GROUPS_DETAILS: '/groups',
  GROUPS_ADD: '/groups',
  GROUPS_EDIT: '/groups',
  GROUPS_DELETE: '/groups',
};

// TODO: change endpoints
export const GROUP_ENDPOINTS = {
  GROUP_REPORTS: '/api/v1/group/{id}/reports_summary',
  GROUP_REPORTS1: '/api/v1/group/{ id }/reports',
  GROUP_SCHEMAS: '/api/v1/group/{ id }/schemas',
  GROUP: '/api/v1/group/{ id }/users',
  GROUP1: '/api/v1/group/{ id }/users',
};

// TODO: change endpoints
export const ANALYTICS_ENDPOINTS = {
  ANALYTICS_REPORTS: '/analytics/reports_by_users/{schema_id}',
  ANALYTICS_REPORTS1: '/analytics/reports_by_users/all',
  // ANALYTICS: '/analytics/reports_by_completion_perc/{schema_id}',
  // ANALYTICS: '/api/v1/analytics/reports_by_completion_perc/all',
  // ANALYTICS: '/api/v1/analytics/reports_by_stage/{schema_id}',
  // ANALYTICS: '/api/v1/analytics/reports_by_stage/all',
  // ANALYTICS: '/api/v1/analytics/reports_by_time/{schema_id}',
  // ANALYTICS: '/api/v1/analytics/reports_by_time/all',
  // ANALYTICS: '/api/v1/analytics/reports_by_schema/failure',
  // ANALYTICS: '/api/v1/analytics/reports_by_schema/success',
  // ANALYTICS: '/api/v1/analytics/reports_by_schema/all',
  // ANALYTICS: '/api/v1/analytics/{schema_id}/reports',
  // ANALYTICS: '/api/v1/analytics/failure',
  // ANALYTICS: '/api/v1/analytics/success',
};

// OLD ENDPOINTS

export const CUSTOMER_ENDPOINTS = {
  CUSTOMERS: '/offer/customers',
  CUSTOMER_SET_STATUS: '/offer/customer/status',
  CUSTOMER_ADD: '/offer/customer',
  CUSTOMER_EDIT: '/offer/customer',
  CUSTOMER_SEND_SMS: '/offer/customers/send-sms',
  CUSTOMER_EXPORT: '/offer/customers/export',
};

export const REQUEST_ENDPOINTS = {
  REQUESTS: '/offer/requests',
  REQUEST_ADD: '/offer/request',
  REQUEST_EDIT: '/offer/request',
  REQUEST_SET_STATUS: '/offer/request/status',
  RESEND_OFFER: '/offer/request/resend',
};

export const CLOSED_DAY_ENDPOINTS = {
  CLOSED_DAYS: '/admin/closed-days',
  CLOSED_DAY_ADD: '/admin/closed-day',
  CLOSED_DAY_DELETE: '/admin/closed-day',
};

export const ORDER_ENDPOINTS = {
  ORDERS: '/offer/orders',
  ORDER_ADD: '/offer/order',
  ORDER_EDIT: '/offer/order',
  ORDER_SET_STATUS: '/offer/order/status',
  ORDER_SET_PAY_STATUS: '/offer/order/pay-status',
  RESEND_OFFER: '/offer/order/resend',
};

export const OFFER_ENDPOINTS = {
  OFFERS: '/offer/order/files',
  OFFER_ADD: '/offer/order/file',
  OFFER_DELETE: '/offer/order/file',
  GET_OFFER: '/offer/order/file',
};

export const CALENDAR_ENDPOINTS = {
  CALENDAR: '/offer/calendar',
  CALENDAR_DAY_DETAILS: '/offer/calendar/details',
};

export const SETTINGS_ENDPOINTS = {
  SETTINGS: '/settings',
};

export const COMMON_ENDPOINTS = {
  MENU: '/menu',
};

export const TELEMARKETING_ENDPOINTS = {
  LEADS: '/telemarketing/leads',
  LEAD_DETAILS: '/telemarketing/lead/details',
  LEAD_STATUS_ADD: '/telemarketing/lead/status',
  LEADS_EXPORT: '/telemarketing/leads/export',
  LEAD_CONTACT: '/telemarketing/lead/contact',
  DATABASES: '/telemarketing/databases',
  DATABASE_ADD: '/telemarketing/database',
};

export const ADMIN_ENDPOINTS = {
  USERS: '/admin/accounts',
  USER_ADD: '/admin/account',
  USER_EDIT: '/admin/account',
  BLOCK: '/admin/account/block',
  SETTING_EDIT: '/admin/setting',
};

export const TRANSLATION_ENDPOINTS = {
  LANGUAGES: '/locale/translation/languages',
  SCAN: '/locale/translation/scan',
  UPDATE: '/locale/translation',
  TRANSLATION: '/locale/translation',
  TRANSLATIONS: '/locale/translations',
};

export const LOCALE_ENDPOINTS = {
  LANGUAGE: '/locale/language',
  LANGUAGES: '/locale/languages',
};

export const HELPER_ENDPOINTS = {
  COUNTRIES: '/helper/countries',
  CUSTOMER_STATUSES: '/helper/customer/statuses',
  REQUEST_STATUSES: '/helper/request/statuses',
  ORDER_STATUSES: '/helper/order/statuses',
  ACCOUNTS: '/helper/accounts',
  SUMMARY: '/dashboard/summary',
  CUSTOMER_TYPES: '/helper/customer/types',
  CUSTOMER_SOURCES: '/helper/customer/sources',
  CUSTOMERS: '/helper/customers',
  HALLS: '/helper/halls',
  EVENTS: '/helper/events',
  COMPANY_NAME: 'offer/customers/company',
  AREA_CODES: 'helper/area-codes',
  PAY_STATUSES: 'helper/order/pay-statuses',
  SUB_STATUS_NOT_INTERESTED: 'helper/request/not-interested/substatuses',
  DATABASE_STATUSES: 'helper/database/statuses',
  LEAD_STATUSES: 'helper/lead/statuses',
  DATABASES: 'helper/databases',
  PROVINCES: 'helper/provinces',
  LEGAL_FORMS: 'helper/legal-forms',
  PKD_CODES: 'helper/pkd-codes',
};
