import { components } from 'react-select';

import styles from './SelectMultiValueLabel.module.scss';

type SelectMultiValueLabelProps = {
  innerProps: {};
  children: any;
  data: any;
  selectProps: any;
};

const SelectMultiValueLabel = ({ innerProps, ...rest }: SelectMultiValueLabelProps) => {
  return <components.MultiValueLabel {...rest} innerProps={{ ...innerProps, className: styles.MultiValueLabel }} />;
};

export default SelectMultiValueLabel;
