import ReactModal from 'react-modal';
import { cnb } from 'cnbuilder';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';

import { hideModalAction } from 'Store/modal/actions';

import { selectModal$ } from 'Store/modal/selectors';

import styles from './ModalProvider.module.scss';

type ModalProviderProps = {
  name: string;
};

ReactModal.setAppElement('#root');

const ModalProvider = ({ name }: ModalProviderProps) => {
  const dispatch = useAppDispatch();
  const {
    isOpen,
    props: { component: Component, useOverflow, useOverflowHidden, shouldCloseOnOverlayClick, className, ...rest },
  } = useAppSelector((state) => selectModal$(state, name));

  const handleOnClose = () => {
    dispatch(hideModalAction(name));
  };

  if (typeof Component !== 'function') {
    return null;
  }

  return (
    <ReactModal
      overlayClassName={styles.Modal__Overlay}
      className={cnb(
        className,
        styles.Modal,
        useOverflow && styles.Modal_overflow,
        useOverflowHidden && styles.Modal_overflowHidden,
      )}
      isOpen={isOpen}
      onRequestClose={handleOnClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Component onClose={handleOnClose} {...rest} />
    </ReactModal>
  );
};

export default ModalProvider;
