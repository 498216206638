import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateFilters } from 'Utils/common';

import * as actionTypes from 'Store/closedDays/actionTypes';

type ClosedDaysState = {
  closed_days: Array<{
    id: number;
    date: string;
    description: string;
    created_at: string;
    created_by: {
      first_name: string;
      id: number;
      last_name: string;
      user_id: number;
    };
  }>;
  total: number;
  currentPage: number;
  filters: {};
  filtersParams: string;
  filtersActive: boolean;
  isReady: boolean;
  filtersOpen: boolean;
};

type initialFiltersType = {
  date: {
    label: string;
    value: string;
  };
  description: {
    label: string;
    value: string;
  };
  created_at: {
    label: string;
    value: string;
  };
  created_by: {
    label: string;
    value: string;
  };
};

const initialFilters: initialFiltersType = {
  date: {
    label: 'Date',
    value: '',
  },
  description: {
    label: 'Description',
    value: '',
  },
  created_at: {
    label: 'Created at',
    value: '',
  },
  created_by: {
    label: 'Created by',
    value: '',
  },
};

const initialState: ClosedDaysState = {
  closed_days: [],
  total: 0,
  currentPage: 1,
  filters: { ...initialFilters },
  filtersParams: '',
  filtersActive: false,
  isReady: false,
  filtersOpen: false,
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.TOGGLE_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        filtersOpen: {
          $set: filtersOpen,
        },
      });
    }
    case actionTypes.SET_CLOSED_DAYS: {
      const { closed_days, total } = action.payload;

      return update(state, {
        closed_days: {
          $set: closed_days,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        currentPage: {
          $set: currentPage,
        },
      });
    }
    case actionTypes.SET_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some(
        (key) => updatedFilters[key].value && !updatedFilters[key].skipActive,
      );

      return update(state, {
        filters: {
          $set: updatedFilters,
        },
        filtersActive: {
          $set: filtersActive,
        },
      });
    }
    case actionTypes.SET_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        filtersParams: {
          $set: filtersParams,
        },
      });
    }
    case actionTypes.RESET_FILTERS: {
      return update(state, {
        filters: {
          $set: initialFilters,
        },
        filtersActive: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_CLOSED_DAYS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_CLOSED_DAYS: {
      return update(state, {
        $set: initialState,
      });
    }
    default:
      return state;
  }
};
