import update from 'immutability-helper';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateFilters } from 'Utils/common';

import * as actionTypes from 'Store/leads/actionTypes';

type LeadsState = {
  leads: Array<{
    id: number;
    name: string;
    city: string;
    phone_1?: string;
    phone_2?: string;
    phone_3?: string;
    email_1?: string;
    email_2?: string;
    email_3?: string;
    status: number;
    updated_at: string;
    updated_by: {
      first_name: string;
      id: number;
      last_name: string;
      user_id: number;
    };
    database: {
      id: number;
      name: string;
    };
    database_id: number;
    last_notes: Array<{
      id: number;
      lead_id: number;
      note: string;
    }>;
  }>;
  total: number;
  currentPage: number;
  filters: {};
  filtersParams: string;
  filtersActive: boolean;
  isReady: boolean;
  filtersOpen: boolean;
  isReadyDetails: boolean;
  lead: {
    id: number;
    status: number;
    county: string;
    created_at: string;
    legal_form: string;
    city: string;
    name: string;
    nip: string;
    pkd: string;
    phone_1?: string;
    phone_2?: string;
    phone_3?: string;
    email_1?: string;
    email_2?: string;
    email_3?: string;
    pkd_description: string;
    province: string;
    street: string;
    zip_code: string;
    www: string;
    database_id: number;
    history: Array<{
      id: number;
      lead_id: number;
      created_at: string;
      created_by: {
        first_name: string;
        id: number;
        last_name: string;
        user_id: number;
      };
      status: number;
      note: string;
    }>;
    contacts: Array<{
      id: number;
      phone: string;
      email: string;
      last_name: string;
      first_name: string;
      description: string;
    }>;
  };
};

type initialFiltersType = {
  database: {
    label: string;
    value: string;
  };
  status: {
    label: string;
    value: string;
  };
  city: {
    label: string;
    value: string;
  };
  nip: {
    label: string;
    value: string;
  };
  phone: {
    label: string;
    value: string;
  };
  email: {
    label: string;
    value: string;
  };
  name: {
    label: string;
    value: string;
  };
  county: {
    label: string;
    value: string;
  };
  province: {
    label: string;
    value: string;
  };
  legal_form: {
    label: string;
    value: string;
  };
  pkd_description: {
    label: string;
    value: string;
  };
  is_email: {
    label: string;
    value: boolean;
  };
};

const initialFilters: initialFiltersType = {
  database: {
    label: 'Databases',
    value: '',
  },
  status: {
    label: 'Status',
    value: '',
  },
  city: {
    label: 'City',
    value: '',
  },
  nip: {
    label: 'NIP',
    value: '',
  },
  phone: {
    label: 'Phone',
    value: '',
  },
  email: {
    label: 'Email',
    value: '',
  },
  name: {
    label: 'Nazwa',
    value: '',
  },
  county: {
    label: 'County',
    value: '',
  },
  province: {
    label: 'Province',
    value: '',
  },
  legal_form: {
    label: 'Legal form',
    value: '',
  },
  pkd_description: {
    label: 'PKD description',
    value: '',
  },
  is_email: {
    label: 'Leads with email',
    value: false,
  },
};

const initialState: LeadsState = {
  leads: [],
  total: 0,
  currentPage: 1,
  filters: { ...initialFilters },
  filtersParams: '',
  filtersActive: false,
  isReady: false,
  filtersOpen: false,
  isReadyDetails: false,
  lead: {
    id: null,
    status: null,
    county: '',
    city: '',
    created_at: '',
    legal_form: '',
    name: '',
    nip: '',
    pkd: '',
    pkd_description: '',
    province: '',
    street: '',
    zip_code: '',
    www: '',
    database_id: null,
    history: [],
    contacts: [],
  },
};

export const reducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.TOGGLE_FILTERS_SIDEBAR: {
      const { filtersOpen } = action.payload;

      return update(state, {
        filtersOpen: {
          $set: filtersOpen,
        },
      });
    }
    case actionTypes.SET_LEADS: {
      const { leads, total } = action.payload;

      return update(state, {
        leads: {
          $set: leads,
        },
        total: {
          $set: total,
        },
      });
    }
    case actionTypes.SET_LEAD_DETAILS: {
      const { lead } = action.payload;

      return update(state, {
        lead: {
          $set: lead,
        },
      });
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;

      return update(state, {
        currentPage: {
          $set: currentPage,
        },
      });
    }
    case actionTypes.SET_FILTERS: {
      const { filters } = action.payload;

      const updatedFilters = updateFilters(state.filters, filters);
      const filtersActive = Object.keys(updatedFilters).some(
        (key) => updatedFilters[key].value && !updatedFilters[key].skipActive,
      );

      return update(state, {
        filters: {
          $set: updatedFilters,
        },
        filtersActive: {
          $set: filtersActive,
        },
      });
    }
    case actionTypes.SET_FILTERS_PARAMS: {
      const { filtersParams } = action.payload || '';

      return update(state, {
        filtersParams: {
          $set: filtersParams,
        },
      });
    }
    case actionTypes.RESET_FILTERS: {
      return update(state, {
        filters: {
          $set: initialFilters,
        },
        filtersActive: {
          $set: false,
        },
      });
    }
    case actionTypes.SET_LEADS_READY: {
      return update(state, {
        isReady: {
          $set: true,
        },
      });
    }
    case actionTypes.SET_LEAD_DETAILS_READY: {
      return update(state, {
        isReadyDetails: {
          $set: true,
        },
      });
    }
    case actionTypes.CLEAR_LEADS: {
      return update(state, {
        $set: initialState,
      });
    }
    case actionTypes.CLEAR_LEAD_DETAILS: {
      return update(state, {
        lead: {
          $set: initialState.lead,
        },
        isReadyDetails: {
          $set: initialState.isReadyDetails,
        },
      });
    }
    default:
      return state;
  }
};
